
.text {
  margin-bottom: 32px;
}

.cardDoc {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  margin-bottom: 32px;
}

.textCode {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  margin-bottom: 40px;
}

.phone {
  white-space: nowrap;
}
