@import '../../../../styles/variables';



.collapse {
  &--active {
    .collapse {
      &__icon {
        transform: rotate(180deg);
      }

      &__head {
        margin-bottom: 24px;
      }
    }
  }

  &__head {
    position: relative;
    display: flex;
      & > * {
          cursor: pointer;
      }
  }

  &__headText {
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;
    margin-right: 16px;
    @include for-mobile-only {
        white-space: break-spaces;
    }
    span {
      display: inline-block;
      font-size: 14px;
      line-height: 20px;
      color: $colorSecondary;
      margin-top: 4px;
    }
  }

  &__icon {
    width: 11px;
    transition: transform .3s ease;
  }

  &__content {
    transition: height .3s ease;
  }

  &__button {
    background-color: transparent;
    text-align: right;
    font-size: 16px;
    line-height: 24px;
    color: $green;
    padding: 0;
    width: 150px;
    margin-left: auto;
    @include for-mobile-only {
        width: auto;
    }
    &--bottom {
      margin-top: 32px;
    }
  }

  &__item {
    font-size: 16px;
    line-height: 24px;
    padding: 16px 20px;
    border-radius: $borderRadiusMiddle;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      white-space: nowrap;
    }

    &--odd {
      background-color: $bgPrimary;
    }

    &--even {
      background-color: $bgSecondary;
    }
  }

  &__result {
    padding: 16px 20px 0;
    text-align: right;
  }
}
