@import '../../../styles/variables';

.contacts {
  h1 {
    margin-bottom: 40px;
  }

  &__cards {
    margin-top: 24px;
    display: grid;
    grid-gap: 16px;

    article {
      p {
        margin-top: 24px;
      }

      b {
        font-size: 18px;
        line-height: 26px;
      }

      a,
      span {
        display: block;
      }

      a {
        color: $green;
        font-size: 21px;
        line-height: 26px;
      }
    }
  }

  &__map {
    margin-top: 32px;
    overflow: hidden;
    border-radius: 8px;
  }

  @include for-tablet {
    &__list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 10px 20px;

      span {
        transition: color 0.3s;
        cursor: pointer;

        &.active {
          color: $green !important;
          pointer-events: none;
        }

        &:hover {
          color: rgba($green, 0.75);
        }
      }
    }

    &__cards {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;

      article {
        a {
          transition: opacity 0.3s;

          &:hover {
            opacity: 0.75;
          }
        }
      }
    }
  }

  @include for-notebook {
    &__list {
      grid-template-columns: repeat(4, 1fr);
    }

    &__cards {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
