@import '../../../styles/variables';

.progress {
    position: relative;
    background: $borderTertiary;
    width: 100%;

    &,
    &__bar {
        height: 6px;
        border-radius: 20px;
    }

    &.small {
        height: 2px;
        .progress__bar {
            height: 2px;
        }
    }

    &__bar {
        position: absolute;
        background: $green;
    }

    &.red {
        .progress {
            &__bar {
                background: $red;
            }
        }
    }
}
