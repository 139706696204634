@import "./../../../../../styles/variables";
.card {
    padding: 20px 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-radius: 8px;
    background: var(--bg-primary, #FFF);
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.05);


    .card__title {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .card__header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .card__titleText {
                font-size: 16px;
                font-style: normal;
                font-weight: bold;
                line-height: 24px;
            }

            .card__questionIcon {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                border-radius: 15px;
                border: solid 1px gray;
                width: 24px;
                height: 24px;
                color: gray;
                text-align: center;
            }
        }
    }

    .transactionList {
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-height: 150px;
        min-height: 150px;
        overflow-y: auto;
        padding-right: 3px;
        &::-webkit-scrollbar {
            width: 3px;
            height: 3px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 20px;
            border: 2px solid $green;
        }

        .transaction {
            display: flex;
            justify-content: space-between;
            .transactionInfo {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
                .transactionIcon {
                    height: 30px;
                    width: 30px;
                }

                .transactionPhone {
                    color: var(--text-primary, #27273F);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px; /* 150% */
                }
            }

            .transactionAmount {

                text-align: right;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 150% */
            }
            .positive {
                color: $green;
            }
            .negative {
                color: $red;
            }
        }
    }
    .card__description{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        color: $iconPrimary;
    }
}
