@import '../../../styles/variables';

.link {
  color: $green;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;

  svg {
    margin-left: 10px;
  }

  @include for-tablet-land {
    transition: color 0.3s;

    svg {
      path {
        transition: stroke 0.3s;
      }
    }

    &:hover {
      color: $colorPrimary;

      svg {
        path {
          stroke: $colorPrimary;
        }
      }
    }
  }
}
