@import '../../../styles/variables';

.tag {
  display: inline-block !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 12px !important;
  padding: 4px 6px 2px !important;
  border-radius: 3px;
  margin: 0 !important;

  & + & {
    margin-left: 6px !important;
  }
}

.green {
  background: rgba($green, 0.1) !important;
  color: $green !important;
}

.red {
  background: rgba($red, 0.1) !important;
  color: $red !important;
}

.gray {
  background: rgba($colorSecondary, 0.1) !important;
  color: $colorSecondary !important;
}
