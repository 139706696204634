@import "../../../styles/variables";

.card {
    h2 {
      margin:0 0 24px;
      font-size: 24px;
      line-height: 24px;
      font-weight: bold;
    }

    @include for-tablet {
        button {
            max-width: 200px;
        }
    }
}

.textWrapper {
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 24px;
    color: $colorPrimary;

    p {
        max-width: 700px;
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.inputsWrapper {
    display: grid;
    gap: 24px;
    margin-bottom: 24px;

    @include for-tablet {
        max-width: 306px;
    }
}

.infoText {
    font-size: 13px;
    line-height: 18px;
    color: $colorSecondary;
}
.buttonWrapper{
    width: 450px;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0px;
    @include for-mobile-only{
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 15px;
      width: 100%;
      justify-items: center;
      button{
        width: 100%;
      }
    }
    p{
      cursor: pointer;
       color: $green;
      @include for-mobile-only{
        text-align: center;
      }
    }
}
