@import '../../styles/variables';

.wrapper {
  padding: 90px 100px 40px;
  margin: -90px -100px -40px;
}
.red {
    color: $red;
}
.form {
  &__label {
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 24px;
    &--mt {
      margin-top: 32px;
    }
  }
  &__line {
    position: relative;
    display: flex;
    & + & {
      margin-top: 24px;
    }
  }
  &__half {
    display: inline-block;
    vertical-align: middle;
    width: calc(50% - 10px);
    &:nth-child(2n) {
      margin-left: 20px;
    }
    &--text {
      font-size: 13px;
      line-height: 15px;
      color: $colorSecondary;
      padding-right: 32px;
    }
  }
   &__amount {
       display: inline-flex;
       width: calc(50% - 10px);
       margin-left: 20px;
   }
  &__sum {
    font-size: 18px;
    line-height: 20px;
    color: $green;
  }
  &__loading,
  &__tooltip {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
  &__loading {
    right: calc(25% - 25px);
  }
  &__error {
    margin-top: 24px;
  }
  &__bottom {
    margin-top: 32px;
  }
  &__policyAgreement {
    margin-top: 12px;
  }
}
