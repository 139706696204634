@import '../../../styles/variables';

.payments {
    padding-top: 48px;

    h2 {
        cursor: pointer;

        span {
            position: relative;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            vertical-align: middle;
            margin-left: 24px;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: $bgSecondary;
            box-shadow: $shadowPrimary;

            svg {
                transition: transform 0.3s;
                position: absolute;
                width: 20px;
                height: 20px;

                path {
                    stroke: $iconPrimary;
                }
            }

            &.active {
                svg {
                    transform: rotate(-180deg);
                }
            }
        }
    }

    &__list {
        margin-top: 24px;
    }

    &__button {
        margin: 32px auto 0;
        width: 190px;
    }

    @include for-tablet {
        padding-top: 60px;

        &__list {
            margin-top: 32px;
        }
    }
}
