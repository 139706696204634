@import '../../../styles/variables';

.top {
    h1 + p {
        margin: 24px 0 32px;
        font-size: 18px;
        line-height: 26px;
    }

    &__group {
        display: grid;
        grid-gap: 10px;
        margin-top: 32px;

        article {
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%) scale(1.5);
                background-image: url(../../../img/arrow.svg);
                background-repeat: no-repeat;
                background-position: center center;
                width: 20px;
                height: 20px;
            }

            img {
                margin-right: 24px;
            }

            span {
                white-space: nowrap;
                font-size: 14px;
                line-height: 20px;
                color: $colorPrimary;
            }

            b {
                display: block;
                margin-top: 10px;
                font-size: 36px;
                line-height: 28px;
                color: $colorPrimary;
            }
        }
    }

    @include for-tablet {
        &__group {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 20px;
        }
    }

    @include for-notebook {
        &__group {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
