@import "../../../styles/variables";

.title {
    font-size: 24px;
    line-height: 24px;
    font-weight: bold;
    margin-bottom: 32px;
}
.titleWrapper{
    margin-bottom: 24px;
    display: flex;
    gap: 20px;
    align-items: center;
    span{
        color: $green;
    }
}
.wrapper{
    margin: 24px 0;
}
.infoWrapper {
    display: grid;
    gap: 20px;
    margin-bottom: 24px;

    @include for-tablet-land {
      div:first-child{
        grid-column-start: 1;
        grid-column-end: 4;
      }

      grid-template-columns: repeat(3, 1fr);
    }

    .info {
        display: flex;
        flex-direction: column;

        &__label {
            margin-bottom: 10px;
            font-size: 14px;
            line-height: 20px;
            color: #878b90;
        }

        &__text {
            font-size: 16px;
            line-height: 16px;
            color: $colorPrimary;
        }
    }
}

.actionBtn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: none;
    margin: 0;
    padding: 0;
    outline: none;

    &__text {
        display: inline-block;
        margin-left: 8px;
        font-size: 16px;
        line-height: 24px;
        color: $green;
    }
}

.ptsTabs {
    display: flex;
    margin: 0 0 24px;

    &__tab {
        margin-right: 16px;
        font-size: 18px;
        line-height: 26px;
        color: $colorPrimary;

        &:last-child {
            margin-right: 0;
        }

        &--active {
            color: $green;
        }
    }
}
.eptsNumber{
  display: flex;
  flex-direction: column;
  align-items: center;
  @include for-mobile-only{
    width: auto;
    button {
      width: 100%;
    }
  }
}

.photoWrapper {
    display: grid;
    gap: 32px 20px;
    width: 100%;
    @include for-tablet-land {
        grid-template-columns: repeat(4, 1fr);
    }
}
.buttonWrapper{
  margin-top: 20px;
  position: relative;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  @include for-mobile-only{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;
    width: 100%;
    justify-items: center;
  }
  p{
    position: absolute;
    left: 216px;
    cursor: pointer;
    color: $green;
    @include for-mobile-only{
      position: relative;
      left: 0;
      text-align: center;
    }
  }
}
