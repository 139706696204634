@import '../../../styles/variables';

.status {
  span {
    font-size: 14px;
    line-height: 20px;
    color: $colorSecondary;
    margin-bottom: 10px;
  }

  p {
    margin-top: 10px;
    margin-bottom: 40px;
  }
}
