@import "../../../styles/variables";

.bannerClub {
    display: flex;
    height: 242px;
    background: white;
    border-radius: 16px;
    position: relative;
    @include for-mobile-only {
        flex-direction: column;
        height: auto;
    }
}

.bannerInfo {
    display: flex;
    flex-direction: column;
    padding: 32px;

    @include for-mobile-only {
        height: 175px;
        order: 2;
        padding: 20px;
    }

    .title {
        color: $colorPrimary;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        margin: 0;
        @include for-mobile-only {
            font-size: 18px;
            line-height: 24px;
        }
    }

    .info {
        color: $iconPrimary;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        margin: 12px 0 34px;
        max-width: 380px;
        @include for-mobile-only {
            font-size: 16px;
            line-height: 20px;
            margin: 6px 0 23px;
        }
    }

    .buttonWrapper {
        display: flex;
        width: 316px;
        height: 50px;
        padding: 12px 40px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;
        border-radius: 8px;
        border: 2px solid $green;
        &:hover{
            color: $green
        }
        @include for-mobile-only {
            width: 100%;
            padding: 12px 20px;
            border: 1px solid $green;
        }
    }
}

.wrapper {
    background: $bgPrimary;
    height: 242px;
    display: flex;
    flex-grow: 1;
    position: relative;
    @include for-mobile-only {
        display: none;
    }

    &:before {
        content: '';
        left: 0;
        width: 20%;
        height: 100%;
        background: white;
        position: absolute;
    }


    .imageBackground {
        height: 242px;
        display: flex;
        flex-grow: 1;
        position: relative;
        clip-path: polygon(13% 0, 98% 0, 85% 100%, 0% 100%);
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
        background: #7DE37C;
        filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.05));
    }
}

.image {
    position: absolute;
    right: 0;
    bottom: 0;
    @include for-mobile-only {
        display: none;
    }
    @include for-tablet {
        max-width: 50%;
    }
    @include for-desktop {
        max-width: none;
    }
}

.mobileWrapper {
    height: 110px;
    background: $bgPrimary;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    @include for-tablet {
        display: none;
    }

    .imageBackground {
        position: relative;
        height: 95px;
        width: 100%;

        .imageRect {
            width: 100%;
        }
    }

    .imageMobile {
        display: flex;
        width: 100%;
        position: absolute;
        bottom: -67px;
    }
}
