@import '../../../styles/variables';

.payments {
    &__group {
        display: grid;
        grid-gap: 10px;

        & + & {
            margin-top: 16px;
            border-top: 1px solid $borderSecondary;
            padding-top: 16px;
        }
    }

    &__line {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    span {
        &:nth-child(2n) {
            text-align: right;
        }

        img {
            display: inline-block;
            vertical-align: sub;
            width: 20px;
            height: 20px;
            margin-right: 6px;
        }

        &.gray {
            color: $colorSecondary;
        }

        &.green {
            color: $green;
        }

        &.orange {
            color: $orange;
        }

        &.small {
            font-size: 14px;
            line-height: 20px;
        }
    }

    @include for-mobile-only {
        span {
            font-size: 14px;
            line-height: 20px;
        }
    }

    @include for-desktop {
        &__head {
            display: grid;
            grid-gap: 10px;
            margin-bottom: 30px;
        }

        &__head,
        &__group {
            grid-template-columns: 3fr 3fr 4fr 3fr 7fr 4fr;
        }

        &__group {
            & + & {
                margin-top: 24px;
                padding-top: 24px;
            }
        }

        span {
            &:nth-child(2n) {
                text-align: left;
            }
        }
    }
}
