@import '../../../styles/variables';

.loans {
  display: flex;
  flex-direction: column;
  &>div:not(:last-child){
      margin-bottom: 15px;
  }

  &__container {
    padding: 40px 30px;
    background-color: $bgSecondary;
    box-shadow: $shadowPrimary;
    border-radius: $borderRadiusLarge;
    &--withCollapse {
      border-radius: 0 0 $borderRadiusLarge $borderRadiusLarge;
    }
    @include for-mobile-only{
        padding: 24px 20px;
    }
  }

  &__wrapperCollapse {
    padding: 30px;
    box-shadow: $shadowPrimary;
    border-radius: $borderRadiusLarge $borderRadiusLarge 0 0;
    &--grace {
      background-color: $warning;
    }
  }

  &__headCollapse {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    //display: flex;
    //align-items: center;
  }

  &__info {
    position: relative;
    display: grid;
    //grid-gap: 16px;
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__grayText {
    display: block;
    font-size: 14px;
    line-height: 20px;
    color: $colorSecondary;
  }

  &__textDesktop {
    display: none;
    @include for-notebook {
      display: inline;
    }
  }

  &__textMobile {
    display: inline;
    @include for-notebook {
      display: none;
    }
  }

  &__top {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    &--higher {
      @include for-tablet {
        margin-bottom: 85px !important;
      }
    }
    @include for-tablet {
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 32px;
    }
    @media (min-width: 870px) {
      flex-direction: row;
    }
    @include for-tablet-land {
      flex-direction: column;
    }
    @include for-notebook {
      flex-direction: row;
    }
  }

  &__subject {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 24px;
    @include for-tablet {
      margin-bottom: 0;
    }
  }

  &__wrapperPayment {
    display: flex;
    flex-direction: column;
    @include for-tablet {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 24px;
    }
    @media (min-width: 870px) {
      justify-content: flex-end;
      margin-top: 0;
    }
    @include for-tablet-land {
      justify-content: space-between;
      margin-top: 24px;
    }
    @include for-notebook {
      justify-content: flex-end;
      margin-top: 0;
    }
  }

  &__carInfo {
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 8px;
    color: $colorPrimary;
  }

  &__datePayment {
    margin-bottom: 24px;
    @include for-tablet {
      margin-bottom: 0;
      margin-right: 32px;
      text-align: left;
    }
    @media (min-width: 870px) {
      text-align: right;
    }
    @include for-tablet-land {
      text-align: left;
    }
    @include for-notebook {
      text-align: right;
    }

    h3 {
      font-size: 18px;
      line-height: 26px;
      font-weight: 700;
    }
  }

  &__datePaymentDate {
    display: flex;
    align-items: center;
    white-space: nowrap;
      h3{
          margin-bottom: 0;
      }
  }

  &__dateClosed {
    display: flex;
    align-items: center;
    margin-top: 24px;
    @media (min-width: 870px) {
      margin-top: 0;
    }
    @include for-tablet-land {
      margin-top: 24px;
    }
    @include for-notebook {
      margin-top: 0;
    }
  }

  &__dateClosedDate {
    font-size: 18px;
    line-height: 26px;
    font-weight: 700;
  }

  &__dateClosedWrapper {
    order: 2;
    @include for-tablet {
      order: 1;
    }
  }

  &__closedIcon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    order: 1;
    @include for-tablet {
      order: 2;
      margin-left: 16px;
      margin-right: 0;
    }
  }

  &__dateTag {
    padding: 2px 10px;
    border-radius: $borderRadiusMiddle;
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    height: 24px;
    display: inline-block;
    &--grace {
      background-color: $orange;
    }
    &--overdue {
      background-color: $red;
    }
  }

  &__countdown {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: $green;
    &--grace {
      color: $orange;
    }
    &--overdue {
      color: $red;
    }
  }

  &__buttons {
    position: relative;
    @include for-tablet {
      width: 219px;
    }
    &--wide {
      width: 285px;
    }
    &--sign {
      position: relative;
      bottom: 0;
      margin-top: 16px;
      @include for-tablet {
        position: absolute;
        width: 265px;
        bottom: -60px;
        right: 0;
      }
    }

    button {
      span {
        span {
          font-size: 24px !important;
          line-height: 24px !important;
          padding-top: 2px;
          span {
              font-size: 24px !important;
              line-height: 24px !important;
            opacity: 0.6;
          }
        }
      }
      svg {
        transition-duration: 0.3s;
        transition-property: opacity, transform;
      }
    }
  }

  //&__textBtn {
  //  span {
  //
  //  }
  //}

  &__progress {
    margin-bottom: 16px;
    @include for-tablet {
      margin-bottom: 8px;
    }
  }

  &__progressTop {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
    @include for-tablet {
      margin-bottom: 20px;
    }
    &--text {
      font-size: 18px;
      line-height: 26px;
      font-weight: 700;
      color: $colorPrimary;
    }

    &--props {
      @include for-mobile-only {
        display: none !important;
      }
      @include for-tablet {
        display: none !important;
      }
      @media (min-width: 870px) {
        display: grid !important;
      }
      @include for-tablet-land {
        display: none !important;
      }
      @include for-notebook {
        display: grid !important;
      }
    }
  }

  &__progressBottom {
    font-size: 14px;
    line-height: 20px;
    display: flex;
    justify-content: space-between;
    margin-top: 18px;
    color: $colorPrimary;
    @include for-tablet {
      font-size: 16px;
      line-height: 24px;
      margin-top: 20px;
    }
  }

  &__remains {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @include for-tablet {
      flex-direction: row;
    }
    &--sum {
      order: 1;
      @include for-tablet {
        order: 3;
      }
    }
    &--divider {
      display: none;
      order: 2;
      @include for-tablet {
        display: inline;
      }
    }
    &--text {
      order: 3;
      @include for-tablet {
        order: 1;
      }
    }
  }

  &__bottom {
    margin-bottom: 8px;
    @include for-tablet {
      display: block;
    }
    @media (min-width: 870px) {
      display: none;
    }
    @include for-tablet-land {
      display: block;
    }
    @include for-notebook {
      display: none;
    }
  }

  &__props {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 8px;
    @include for-tablet {
      grid-template-columns: auto auto auto;
      margin-right: auto;
      align-items: center;
      grid-gap: 20px;
      display: flex;
    }
    @media (min-width: 870px) {
      margin-left: 32px;
    }
    @include for-tablet-land {
      margin-left: 0;
      display: flex;
    }
    @include for-notebook {
      margin-left: 32px;
    }
  }

  &__prop {
    display: flex;
    align-items: flex-start;
    &--width {
      @include for-mobile-only {
        grid-column: span 2;
      }
    }
    img {
      margin-right: 8px;
    }
  }
  &__auto{
      @extend .loans__prop;
      cursor: pointer;
  }

  &__number {
    display: flex;
    flex-wrap: wrap;
    & > span {
      margin-right: 20px;
      @include for-tablet {
        margin-right: 24px;
      }
    }
  }

  &__mfo {
    display: inline-block;
    white-space: nowrap;
  }

  &__tax {
    display: grid;
    grid-template-columns: 1fr 104px;
    grid-gap: 8px;

    &--half {
      grid-template-columns: 1fr !important;
    }

    span{
      display: block;
      font-size: 14px;
      line-height: 20px;
      color: $colorSecondary;
    }

    b {
      display: inline-block;
      margin-right: 6px;
      line-height: 16px;

      &.late {
        color: $red;
      }
    }

    p {
      span {
        vertical-align: text-bottom;
      }
    }
  }

  &__button {
    display: grid;
    grid-gap: 16px;
    align-items: center;

    &--flex {
      grid-template-columns: 24px 1fr;
    }

    svg {
      transition-duration: 0.3s;
      transition-property: opacity, transform;
      position: absolute;
      top: 3px;
      right: -4px;
      pointer-events: none;
      width: 30px;
      height: 30px;
    }
  }

  @include for-mobile-only {
    &__name {
      span {
        display: inline-block;
        margin-right: 6px;
      }

    }
  }

  @include for-desktop {
    //&__info {
    //  grid-template-columns: 400px 1fr 230px;
    //  align-items: flex-end;
    //}
    .main{
      margin-left: 24px;
      vertical-align: text-bottom;
    }
    &__name {
      white-space: nowrap;

      h1 {
        line-height: 36px;
      }


      span {
        display: block;
        margin-bottom: 2px;
      }

      p {
        white-space: normal;
        margin-top: 4px;
      }
    }

    &__tax {
      grid-template-columns: 1fr 120px;
    }

    &__button {
      grid-template-columns: 24px 140px 30px;
      text-align: right;

      svg {
        position: relative;
        top: auto;
        right: auto;
      }
    }
  }
}
