.notifications {
  h2 {
    margin-bottom: 24px;
  }

  article {
    margin-bottom: 16px;
  }

  &__button {
    width: 190px;
    margin: 0 auto;
  }
}
