@import '../../styles/variables';

.footer {
  padding: 48px 20px 40px;

  &__socials {
    margin-bottom: 32px;

    a {
      display: inline-block;
      width: 24px;
      height: 24px;

      svg {
        width: 100%;
        height: 100%;
      }

      + a {
        margin-left: 16px;
      }
    }
  }

  &__links {
    a {
      display: block;
      color: $colorSecondary;

      + a {
        margin-top: 10px;
      }
    }
  }

  @include for-tablet {
    padding: 60px 40px 72px;
  }

  @include for-tablet-land {
    padding: 110px 40px 72px 340px;

    &__links {
      display: flex;

      a + a {
        margin: 0 0 0 24px;
      }
    }

    a {
      transition: color 0.3s;

      &:hover {
        color: $green;
      }
    }

    svg {
      path {
        transition: fill 0.3s;
      }

      &:hover {
        path {
          fill: $green;
        }
      }
    }
  }

  @include for-desktop {
    max-width: 1440px;
  }

  @include for-wide {
    padding: 110px 0 72px;
    margin: 0 auto;
    max-width: 1060px;
  }
}
