@import '../../../styles/variables';

.wrapper {
  position: relative;
  cursor: pointer;
  height: 56px;
  border: 1px solid $borderPrimary;
  border-radius: $borderRadiusMiddle;
  padding: 0 18px;
  outline: none;
}

.items {
  position: absolute;
  top: 54px;
  left: -1px;
  min-width: inherit;
  width: calc(100% + 2px);
  z-index: 2;
  border-radius: $borderRadiusMiddle;
  border: 1px solid $borderPrimary;
  max-height: 300px;
  overflow-y: auto;
}
.option {
  min-height: 45px;
  background: white;
  padding-left: 26px;
  &:hover {
    background: #eef3f9
  }
}

.title {
  width: 100%;
  height: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #575757;
  display: flex;
  align-items: center;
}
.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
.label {
  position: absolute;
  top: -6px;
  left: 13px;
  font-size: 13px;
  line-height: 13px;
  padding: 0 4px;
  color: $colorSecondary;
  background-color: $bgSecondary;
}
.imageWrapper {
  transform: scale(1.5);
  transition: transform 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rotate {
  transform: rotate(180deg) scale(1.5);
}

.cardWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    margin-right: 6px;
    width: 28px;
    height: auto;
  }

  span {
    font-size: 18px;
    line-height: 32px;
  }
}
