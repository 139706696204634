@import '../../../styles/variables';
.loanDocs{
  color: $green;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  width: fit-content;
  display: flex;
  align-items: center;

  &__image{
    margin-right: 16px;
    margin-top: -3px;
  }
  &__DocsNumber{
    font-size: 14px;
    margin-left: 16px;
    border-radius: 50%;
    color: white;
    background: $green;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;

    span {
      display: inline-block;
      padding-top: 2px;
    }
  }

  &__list{
      display: flex;
      flex-direction: column;
      gap: 20px;
    //margin-left: 15px;
  }

  &__listItem{
    cursor: pointer;
    a{
      color: $green;
    }
  }
  &__buttonWrapper{
    margin-top: 24px;
  }
}
