@import '../../../styles/variables';

.payment {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;

  p {
    span {
      font-size: 14px;
      line-height: 20px;
      color: $colorSecondary;
    }

    b {
      display: block;
      margin-top: 10px;
      font-size: 16px;
      line-height: 16px;
    }
  }
  hr {
    margin: 4px 0 12px;
    grid-column-start: 1;
    grid-column-end: 3;
  }

  article {
    b {
      display: block;
      margin: 19px 0 10px;

      span {
        color: $green;
      };
    }

    p {
      font-size: 14px;
      line-height: 20px;
      color: $colorSecondary;
    }
  }

  ul {
    margin-top: 32px;
    padding: 20px;
    padding-inline-start: 40px;
    background: rgba($orange, 0.15);
    border-radius: 8px;
    list-style: none;

    li {
      &::before {
        content: "\2022";
        color: $orange;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }

      + li {
        margin-top: 16px;
      }
    }
  }

  ul,
  label {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  label {
    margin: 12px 0;
  }

  @include for-mobile-only {
    p:first-child,
    button,
    article {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    article {
      display: flex;
      align-items: flex-start;

      img {
        margin-right: 16px;
      }

      + article {
        margin-top: 12px;
      }
    }

    &--active {
      p {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
  }

  @include for-tablet {
    &--default,
    &--active {
      grid-template-columns: 2fr 3fr 2fr 162px;
      align-items: center;
    }

    &--extra {
      grid-template-columns: repeat(3, 1fr);
    }

    p {
      b {
        font-size: 18px;
        line-height: 18px;
      }
    }

    hr,
    ul,
    label {
      grid-column-end: 4;
    }

    hr {
      margin: 10px 0 20px;
      grid-column-end: 4;
    }

    ul {
      padding: 30px;
      padding-inline-start: 50px;
    }

    label {
      margin: 24px 0;
    }
  }

  @include for-notebook {
    position: relative;

    &__button {
      margin-top: 40px;

      &.absolute {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 162px;
        margin: 0;
      }
    }

    &__steps {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.info {
  &__head {
    color: $colorSecondary;
    margin-bottom: 16px;
  }
  &__list {
    margin-bottom: 32px;

    display: grid;
    grid-gap: 10px;
  }
}
