@import '../../../styles/variables';

.user {
    &__main {
        display: flex;
        align-items: center;
    }

    &__image {
        cursor: pointer;
        position: relative;
        width: 40px;
        height: 40px;
        background-color: $bgSecondary;
        border-radius: 50%;
        margin-left: -8px;
        overflow: hidden;
    }

    &__avatar,
    &__avatarPlaceholder {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__avatarPlaceholder {
        width: 24px;
        height: 24px;
    }

    &__name {
        padding: 0 !important;
    }

    &__extra {
        padding: 0 0 0 40px !important;
        margin-top: -12px;
        &:hover{
            color: $green;
            opacity: 0.8;
        }

        span {
            &:nth-child(1) {
                display: block;
            }
            &:nth-child(2) {
                display: flex;
            }
        }

        svg {
            margin: 0 8px !important;
        }
    }

    &__logout {
        display: none;
    }

    @include for-tablet-land {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;

        &__image {
            margin-right: 8px;

            img {
                margin: 0;
            }
        }

        &__extra {
            padding: 0 24px 0 0 !important;
            margin-top: 0;

            svg {
                margin: 0 10px 0 0 !important;
            }
        }

        &__name {
            color: $colorPrimary;
            margin-right: 16px;
            position: relative;
        }

        &__logout {
            position: absolute;
            top: 0;
            left: 0;
            right: -16px;
            bottom: -10px;
            cursor: pointer;
            display: block;

            img {
                position: absolute;
                top: 6px;
                right: 0;
                transition: transform 0.3s;
            }

            div {
                position: absolute;
                top: 32px;
                right: -14px;
                padding: 12px 20px;
                border-radius: 8px;
                background-color: $bgSecondary;
                box-shadow: $shadowPrimary;
                text-align: center;
                pointer-events: none;
                opacity: 0;
                transition: opacity 0.3s;

                &::before {
                    content: '';
                    position: absolute;
                    top: -6px;
                    right: 14px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 6px 6px 6px;
                    border-color: transparent transparent $bgSecondary transparent;
                }

                p {
                    text-align: left;
                    transition: color 0.3s;
                    position: relative;
                    z-index: 1;

                    &:after {
                        content: '';
                        position: absolute;
                        left: -20px;
                        top: -4px;
                        width: 123px;
                        height: 30px;
                        z-index: -1;
                        transition: background-color 0.3s;
                    }

                    &:hover {
                        &::after {
                            background-color: $bgPrimary;
                        }
                    }

                    + p {
                        margin-top: 8px;
                        margin-bottom: 0;
                    }
                }
            }

            &.active {
                img {
                    transform: rotate(-180deg);
                }

                div {
                    pointer-events: inherit;
                    opacity: 1;
                }
            }
        }
    }
}

.green {
    color: $green;
}
