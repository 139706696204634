@import '../../../styles/variables';


.uploadCard {

    &__info {
        display: flex;
        align-items: center;

        @include for-tablet-land {
            flex-direction: column;
            align-items: flex-start;
        }
        @include for-mobile-only {
            margin-bottom: 0;
        }
    }

    &__photo {
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 130px;
        height: 100px;
        border-radius: 16px;
        margin-right: 16px;
        margin-bottom: 0;
        cursor: default;
        position: relative;

        &.notUploaded {
            border: 2px dashed $borderPrimary;
            cursor: pointer;
        }
        &.errorFrame{
            border-color: $red;
        }

        @include for-tablet-land {
            &:hover {
                .uploadCard__action {
                    opacity: 1;
                }
            }
        }

        //img {
        //    height: auto;
        //    max-height: 100%;
        //    width: 80.2%;
        //}

        @include for-tablet-land {
            width: 100%;
            min-height: 173px;
            margin-bottom: 20px;
            margin-right: 0;
        }
    }

    &__placeholder {
        height: auto;
        max-height: 100%;
        width: 80.2%;
    }

    &__userPhoto {
        height: 100% !important;
        width: auto !important;
        max-width: 100% !important;
    }

    &__default {
        display: flex;
        flex-direction: column;
        align-items: center;

    img {
      width: 32px !important;
      height: 32px !important;

      @include for-tablet-land {
        width: 66px !important;
        height: 66px !important;
      }
    }

        span {
            color: $green;
        }
    }

    &__success {
        width: 24px !important;
        height: 24px !important;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
    }

    &__action {
        opacity: 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background: rgba(255, 255, 255, 0.7);
        backdrop-filter: blur(10px);
        transition: opacity .3s;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        border-radius: 16px;

        &.active {
            opacity: 1 !important;
            pointer-events: auto;
        }

        @include for-tablet-land {
            flex-direction: row;
            pointer-events: auto;
        }
    }

    &__actionBtn {
        display: flex;
        align-items: center;
        cursor: pointer;
        width: 93px;

        @include for-tablet-land {
            flex-direction: column;
            width: auto;
        }

        &:first-child {
            margin-right: 0;
            margin-bottom: 8px;
            @include for-tablet-land {
                margin-right: 20px;
                margin-bottom: 0;
            }


        }
        .changeButton {
            color: $green;
        }

        .deleteButton {
            color: $red;
        }

        img {
            width: 24px;
            height: 24px;
            margin-bottom: 4px;
            @include for-tablet-land {
                width: 32px;
                height: 32px;
            }
        }
    }

    &__loader {
        position: relative;
    }

    .title {
        word-break: break-word;
        display: block;
        text-align: left;
    }

    input[type="file"] {
        display: none;
    }

    .errorText {
        color: $red;
        @include for-mobile-only{
            display: none;
        }
    }
    .errorText__mobile{
        display: none;
        color: $red;
        margin-top: 10px;
        @include for-mobile-only{
            display: block;
        }
    }

}
