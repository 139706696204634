@import '../../../styles/variables';

.back {
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
    transform: rotate(180deg);
    margin-right: 16px;

    path {
      stroke: $colorPrimary;
    }
  }
}
