@import '../../../styles/variables';

.services {
    padding-top: 48px;

    h2 {
        margin-bottom: 24px;
    }

    &__group {
        margin-top: 24px;
    }

    &__line {
        position: relative;

        span {
            display: block;
            margin-bottom: 10px;
            font-size: 14px;
            line-height: 20px;
            color: $colorSecondary;
        }

        p {
            &.green {
                color: $green;
            }
        }

        button {
            position: relative;
            z-index: 1;
        }

        a {
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }
    }

    hr {
        margin: 24px 0;
    }

    @include for-mobile-only {
        &__line {
            p:first-child {
                margin-bottom: 16px;
            }

            p:not(p:first-child),
            span {
                display: inline-block;
                vertical-align: top;
                width: 50%;
            }

            button {
                margin-top: 30px;
            }
        }
    }

    @include for-tablet {
        padding-top: 60px;

        &__line {
            display: grid;
            grid-template-columns: 4fr 1fr 2fr 140px;
            grid-column-gap: 20px;
            align-items: center;

            button {
                height: 40px;
            }

            &--head {
                margin-bottom: 24px;
            }
        }
    }
}
