@import '../../../styles/variables';

.noData {
  min-height: 23vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  &Svg {
    svg {
      display: block;
      width: 160px;
      height: 160px;
      margin-bottom: 24px;
      opacity: 0.5;
    }
  }



  &Icon {
    width: 48px;
    height: 48px;
    margin-bottom: 24px;
  }

  &Title {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 16px;
  }

  &Subtitle {
    font-size: 16px;
    line-height: 24px;
  }

  &Button {
    margin-top: 24px;
    width: 180px;
  }
}
