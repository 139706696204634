@import "../../../styles/variables";

.repeaterModeration {
    position: fixed;
    bottom: 5%;
    right: 5%;
    z-index: 20;
    background: white;
    border-radius: 8px;
}
.moderationTabs{
    min-height: 70%;
    box-shadow: $shadowPrimary;
    border-radius: 8px;
    border: 1px solid $shadowPrimary;
    &__tab{
        padding: 20px;
    }
    &__tabWrapper{
        margin-top: 20px;
        & > div > * {
            margin-bottom: 15px;
        }
    }

}
.moderationClose{
    margin-left: auto;
    width: fit-content;
}
.moderationSelect{
    margin-right: auto;
    width: 300px;
    position: absolute;
    top: 0;
    left: 0;
}

.codeForm{
    &__wrapper{
        display: grid;
        grid-gap: 20px 24px;
        grid-template-columns: repeat(3, 1fr);
        align-items: center
    }
}
.buttonsLayout{
    button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 16px 40px;
        width: fit-content;
    }
}
