@import '../../../../../styles/variables';
.card {
    padding: 20px 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;
    border-radius: 8px;
    background: var(--bg-primary, #FFF);
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.05);
    @include for-mobile-only {
        flex-wrap: wrap;
        flex-direction: column;
    }
    .card__title {
        display: flex;
        flex-direction: column;
        gap: 4px;
        @include for-mobile-only {
            flex: 1;
        }

        .card__header {
            display: flex;
            gap: 24px;
            align-items: center;

            .card__titleText {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }
            .card__status{
                display: flex;
                gap: 10px;
            }
            .card__questionIcon {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                //border-radius: 15px;
                //border: solid 1px gray;
                width: 24px;
                height: 24px;
                color: gray;
                text-align: center;
                cursor: pointer;
            }
        }
        .card__headerMobile{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 5px;
            .card__statusMobile{
                flex-grow: 1;
                width: 100%;
            }

        }

        .card__subtitle {
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            padding: 2px 6px 4px;
            width: fit-content;
            border-radius: 3px;
        }
    }
    .progress__wrapper{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        @include for-mobile-only {
            flex-grow: 1;
        }
    }
    .card__cell {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .card__cellKey {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-right: 24px;
            p {
                margin: 0;
            }
        }

        .card__cellValue {
            display: flex;
            align-items: center;
            gap: 4px;

            .card__cellValueText {
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 26px;
                margin: 0;
            }

            .card__cellIcon {
                width: 24px;
                height: 24px;
            }
        }
    }
}

.bronze {
    background: #CD7F32;
    color: white;
}

.silver {
    background: #c0c0c0;
}

.gold {
    background: #FFD700;
}
