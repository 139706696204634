@import '../../../styles/variables';

.info {
    padding-top: 48px;

    h2 {
        margin-bottom: 24px;
    }

    p + p {
        margin-top: 20px;
    }

    b {
        display: flex;
        align-items: flex-start;

        &.password {
            i {
                margin-top: -4px;
            }
        }
    }

    i {
        content: url(../../../img/edit.svg);
        margin-left: 16px !important;
    }

    .wrapper{
      display: flex;
      @include for-mobile-only{
        flex-direction: column;
      }
    }
    .photoWrapper{
        margin-right: 40px;
        @include for-mobile-only{
          order: 1;
          margin:  0 0 24px 0;
        }
    }
    .infoWrapper{
        display: flex;
        flex-direction: column;
        margin-bottom: -24px;
        min-width: 0;
        & > div {
            margin-bottom: 24px;
        }
        @include for-mobile-only{
          order: 2;
        }
    }

    @include for-tablet {
        padding-top: 60px;

        p + p {
            margin-top: 24px;
        }
    }
    .emailConfirmationButton {
        margin-top: 16px;
        width: 211px;
    }
}
