@import '../../styles/variables';


.notification {
  i {
    background-color: $green;
    border-radius: 50%;
    height: 8px;
    width: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      padding-top: 2px;
    }

    + span {
      margin: 0;
      padding: 1px 1px 0 0;
    }
  }
}

.line {
  & + & {
    margin-top: 8px;
  }
}

.showAll {
  display: block;
  width: 190px;
  margin: 16px auto 0;
}

@include for-mobile-only {
  .notification {
    position: relative;

    span {
      display: none;
    }

    i {
      position: absolute;
      top: -4px;
      right: -4px;
    }
  }
}

@include for-tablet {
  .notification {
    transition: opacity 0.3s;

    span {
      margin: 0 9px;
      font-size: 14px;
      line-height: 20px;
    }

    i {
      height: 24px;
      width: 24px;
      font-style: normal;
      color: $colorInverse;
    }

    &:hover {
      opacity: 0.86;
    }
  }
}
