@import '../../../styles/variables';

.line {
    cursor: pointer;
    display: flex;
    align-items: center;

    img {
        width: 24px;
        height: 24px;
    }
}

.arrow {
    box-shadow: $shadowRepeater;
    &--top {
        transform: rotate(180deg);
    }
}

.tooltip {
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s ease;

    &,
    &::before {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &::before {
        content: '';
    }

    i {
        position: fixed;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 6px 6px 6px;
        border-color: transparent transparent $bgSecondary transparent;

        + div {
            position: fixed;
            width: calc(100vw - 34px);
            max-width: 388px;
            padding: 24px;
            background: $bgSecondary;
            border-radius: 8px;
            box-shadow: $shadowRepeater;
            -webkit-box-shadow: $shadowRepeater;
            -moz-box-shadow: $shadowRepeater;
        }
        + div.repeaterStyle{
          background: $borderTertiary;
        }
    }

    &.big {
        i + div {
            max-width: 1046px;
        }
    }

    &.show {
        pointer-events: inherit;
        opacity: 1;
    }

    @include for-tablet-land {
        &.big {
            i + div {
                width: calc(100vw - 500px);
            }
        }
    }
}
