@import "../../../styles/variables";

.titleWrapper{
    display: flex;
    margin-bottom: 32px;
    @include for-mobile-only{
        display: flex;
        flex-direction: column;
    }
}

.title {
    font-size: 24px;
    line-height: 24px;
    font-weight: bold;
    margin-bottom: 16px;
    @include for-tablet {
        margin-right: 32px;
        margin-bottom: 0;
    }
}
.fourColumn {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 1fr;
    @include for-tablet {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 32px 20px;
    }
    .leftHalf {
        @include for-tablet {
            grid-column-start: 1;
            grid-column-end: 3;
        }
    }
    .rightHalf {
        @include for-tablet {
            grid-column-start: 3;
            grid-column-end: 5;
        }
    }

    .wide {
        @include for-tablet {
            grid-column-start: 1;
            grid-column-end: 4;
        }
    }

    .row {
        @include for-tablet {
            grid-column-start: 1;
            grid-column-end: 5;
        }
    }
}

.threeColumn{
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 1fr;
    @include for-tablet {
        grid-gap: 32px 20px;
        grid-template-columns: repeat(3, 1fr)
    }
    .wide {
        @include for-tablet {
            grid-column-start: 1;
            grid-column-end: 3;
        }
    }

    .row {
        @include for-tablet {
            grid-column-start: 1;
            grid-column-end: 4;
        }
    }

}

.carInfoWrapper{
    @include for-tablet-land {
        div:first-child{
            grid-column-start: 1;
            grid-column-end: 4;
        }
    }
}

.actionBtn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: none;
    margin: 0;
    padding: 0;
    outline: none;
    flex-shrink: 0;
    @include for-mobile-only{
       align-items: flex-start;
        justify-content: flex-start;
    }

    &__text {
        display: inline-block;
        margin-left: 8px;
        font-size: 16px;
        line-height: 24px;
        color: $green;
    }
}

.finalCheckedWrapper{
    margin: 24px 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    @include for-tablet {
        margin: 32px 0;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
    }
}
.finalChecked{
    padding: 14px;
    display: flex;
    align-items: center;
    background: #F2F9F3;
    border-radius: 8px;
    img{
        margin-right: 10px;
    }
}
.infoWrapper{
    margin: 40px 0;
    & > div:first-child{
        margin-bottom: 16px;
    }
}
.checkboxWrapper{
    @include for-mobile-only {
        padding: 0 6.36%;
    }
    & > div {
        padding: 16px 20px;
        border-radius: $borderRadiusMiddle;
        font-weight: 500;
        background: $accept;
        @include for-tablet {
            padding: 30px;
            font-size: 18px !important;
            border-radius: $borderRadiusLarge;
        }
        & > div {
            padding: 0;
            & > label {
                & > div {
                    & > span {
                        font-size: 16px !important;
                        line-height: 24px !important;
                        font-weight: 600;
                        @include for-tablet {
                            font-size: 18px !important;
                            line-height: 26px !important;
                        }
                    }
                }
            }
        }
    }
}
.final__modal{
    margin-bottom: 16px;
}
