@import '../../../styles/variables';

.modal {
  &__text {
    margin-bottom: 40px;
  }

  img {
    vertical-align: middle;
  }

  &__input {

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    &--small {
      width: 50%;
      display: inline-block;
      vertical-align: middle;
      margin-right: 24px;
    }
  }

  &__errorMessage {
    color: $red;
    margin-top: 10px;
  }

  &__btn {
    margin-top: 40px;
  }

  &__tabs {
    margin-bottom: 40px;
  }

  &__loader {
    display: none;
    &Active {
      display: inline-block;
      animation: loader 1.3s linear infinite;
    }
  }

  &__errorIcon {
    display: none;
    &Active {
      display: inline-block;
    }
  }

  &__successIcon {
    display: none;
    &Active {
      display: inline-block;
    }
  }
  &__timer {
    color: $colorSecondary;
    margin-top: 24px;
  }
}

@keyframes loader {
  100% {
    transform: rotate(360deg);
  }
}
