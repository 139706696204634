// COLORS

// color
$colorPrimary: #27273F;
$colorSecondary: #878B90;
$colorInverse: #fff;

// background
$bgPrimary: #F4F8FA;
$bgSecondary: #fff;
$bgInverse: #27273F;

// border
$borderPrimary: #DDE0E4;
$borderSecondary: #EFF3F6;
$borderTertiary: #F3F3F4;
$borderDark: #CED1D6;

// icon
$iconPrimary: #6E7491;

// others
$red: #FF3F42;
$green: #4CAF50;
$orange: #FFB800;
$blue: #4281FF;

$error: #FFECEC;
$warning: #FFF4D9;
$accept: #EDF7EE;

// shadow
$shadowPrimary: 0 3px 8px rgba(0, 0, 0, 0.05);
$shadowSecondary: 0 3px 20px rgba(0, 0, 0, 0.1);
$shadowRepeater: 5px 4px 9px -1px rgba(128, 128, 128, 0.6);


// SIZES
$mobile: 360px;
$tablet: 768px;
$tabletLand: 1024px;
$notebook: 1240px;
$desktop: 1440px;

$mobileMax: $tablet - 1px;
$tabletMax: $tabletLand - 1px;
$tabletLandMax: $notebook - 1px;
$notebookMax: $desktop - 1px;

$maxContainer: 1440px;

//BORDER-RADIUS

$borderRadiusSmall: 4px;
$borderRadiusMiddle: 8px;
$borderRadiusLarge: 16px;

@mixin for-mobile-only {
    @media screen and (max-width: $mobileMax) {
        @content;
    }
}

@mixin for-mobile-new {
    @media screen and (min-width: $mobile) {
        @content;
    }
}

@mixin for-tablet {
    @media screen and (min-width: $tablet) {
        @content;
    }
}

@mixin for-tablet-land {
    @media screen and (min-width: $tabletLand) {
        @content;
    }
}

@mixin for-notebook {
    @media screen and (min-width: $notebook) {
        @content;
    }
}

@mixin for-desktop {
    @media screen and (min-width: $desktop) {
        @content;
    }
}

@mixin for-wide {
    @media screen and (min-width: 1880px) {
        @content;
    }
}

@mixin for-touch-device {
    @media (hover: none) and (pointer: coarse) {
        @content;
    }
}

@mixin for-cursor-device {
    @media (hover: hover) and (pointer: fine) {
        @content;
    }
}

