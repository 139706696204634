@import '../../../styles/variables';

.history {
  padding-top: 48px;

  h2 {
    margin-bottom: 24px;
  }

  &__table {
    margin-top: -16px;
  }

  &__row{
      img {
          width: 24px;
          height: 24px;
      }
  }
  &__line {
    padding: 16px 0;


    & + & {
      border-top: 1px solid $borderSecondary;
    }
  }

  &__flex {
    display: flex;
    justify-content: space-between;

    img {
      width: 30px;
      height: 30px;
      margin-right: 16px;
    }

    > div {
      display: grid;
      grid-gap: 4px;
      width: 100%;
    }
  }

  &__button {
    width: 190px;
    margin: 32px auto 0;
  }

  @include for-mobile-only {
    &__line {
      span {
        font-size: 14px;
        line-height: 20px;

        &.right {
          min-width: 50px;
          text-align: right;
        }
      }
    }
  }

  @include for-desktop {
    &__line {
      padding: 24px 0;
    }

    &__row {
      display: grid;
      grid-template-columns: 2fr 3fr 2fr 2.5fr 4fr 7fr 4fr;
      grid-column-gap: 10px;
      grid-row-gap: 30px;
      align-items: center;

      &_head {
        padding-bottom: 6px;
      }
    }

    &__button {
      margin: 40px auto 0;
    }
  }
}

.orange {
  color: $orange;
}

.green {
  color: $green;
}

.red {
  color: $red;
}

.mini {
  font-size: 14px;
  line-height: 20px;
  color: $colorSecondary;
}
