@import '../../../styles/variables';

.skeletline {
  position: relative;
  display: inline-block;
  font-size: inherit !important;
  line-height: inherit !important;
  overflow: hidden;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  &::before {
    background-color: white;
    border-radius: 4px;
  }
  &.active::before{
    background-color: white;
    border-radius: 4px;
    opacity: .1;
  }

  &::after {
    transform: translateX(-100%);
    background: linear-gradient(90deg, transparent, $bgSecondary, transparent);
    animation: ssc-loading 1.5s infinite;
  }

  &.active::after{
      transform: translateX(-100%);
      background: linear-gradient(90deg, transparent, $green, transparent);
      animation: ssc-loading 1.5s infinite;
  }

  @keyframes ssc-loading {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(100%);
    }
  }
}
