@import '../../../styles/variables';

.confirm {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px 16px;

  h2 {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  @include for-mobile-only {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 40px);
    max-width: 400px;
  }

  @include for-tablet {
    grid-gap: 32px 16px;

    h2 {
      margin: 0;
    }
  }
}
