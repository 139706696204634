@import '../../../styles/variables';

.currency{
  path {
    fill: #1890ff;
  }
  .red{
    fill: $red;
  }
  .grey{
    fill: $colorSecondary;
  }
  .dark{
    fill: $colorPrimary;
  }
  .green{
    fill: $green;
  }


}
