@import "../../../styles/variables";

.privacy {
    &__head {
        font-size: 24px;
        line-height: 24px;
        font-weight: bold;
        margin-bottom: 24px;
    }

    &__signed{
      position: relative;
      color: $green;
      display: flex;
      align-items: center;
      img {
        margin-right: 8px;
        margin-bottom: 3px;
      }
    }

    &__button {
        padding-left: 32px;
        padding-right: 32px;
    }

    &__signature {
         h6 {
             font-size: 16px;
             line-height: 24px;
             margin-bottom: 16px;
             @include for-tablet {
                 font-size: 18px;
                 line-height: 26px;
             }
         }

        p {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 24px;
            @include for-tablet {
                font-size: 16px;
                line-height: 24px;
            }
        }

        button {
            width: fit-content;
        }
    }

    &__modal {
        display: grid;
        grid-gap: 32px;

        &_wrapper{
            p:first-child{
                margin-bottom: 20px;
            }
        }

        &_status {
            display: flex;

            b {
                color: $green;
                width: 240px;
                margin-right: 10px;
                cursor: pointer;
                margin-bottom: 16px;
            }

            span {
                font-size: 14px;
                line-height: 20px;
                color: $colorSecondary;
            }
        }
    }
}
.publicOfficialCollapse{
    display: flex;
    flex-direction: column;
    .note{
        margin-top: 30px;
        color: #878B90;
        font-size: 13px;
    }
}

.inputsGroup {
    margin-top: 26px;
    &__main {
        display: grid;
        gap: 24px;
        grid-template-columns: 1fr;
        @include for-tablet-land {
            grid-template-columns: 2fr 1fr

        }
    }
}
