@import '../../../styles/variables';

.collapse {
  border-radius: $borderRadiusLarge $borderRadiusLarge 0 0;

  &.active {
    .collapse{
      &__chevron {
        &:after {
          transform: rotate(-180deg);
        }
      }
      &__text {
        & > p {
          text-overflow: unset;
          overflow: unset;
        }
      }
    }
  }

  &--grace {
    background-color: $warning;
  }
  &--overdue {
    background-color: $error;
  }
  &--onlyOffice {
    background-color: $error;
  }
  &--extensionAgreement{
    background-color: rgba(66, 129, 255, 0.15);
  }


  &__container {
    padding: 20px;
    display: flex;
    align-items: flex-start;
    position: relative;
    justify-content: space-between;

    @include for-tablet {
      padding: 30px;
    }

  }

  &__img   {
    position: absolute;
    top: 17px;
    @include for-tablet {
      top: 27px;
    }
  }

  &__text {
    position: relative;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    max-width: calc(100% - 40px);
    overflow: hidden;
    height: 20px;
    padding-left: 40px;
    transition: 0.3s ease height;
    p {
      margin-bottom: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      &:last-child {
        margin-bottom: 0;
      }
      &:nth-last-child(-n+2) {
        margin-bottom: 0;
      }
    }
    a:hover{
      color: $green;
    }
  }

  &__ghost {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    padding-left: 40px;
    p {
      margin-bottom: 10px !important;
      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }

  &__chevron {
    position: relative;
    width: 24px;
    height: 24px;
    margin-left: 16px;
    cursor: pointer;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 4px;
      width: 20px;
      height: 20px;
      background-image: url(./img/chevron.svg);
      background-size: 20px 20px;
      background-repeat: no-repeat;
      background-position: center center;
      transition: 0.3s ease transform;
    }
  }
}

