@import '../../../styles/variables';

.cards {
    h1 + h2 {
        margin: 24px 0;
    }

    @include for-tablet {
        h1 + h2 {
            margin: 40px 0 32px;
        }
    }
}
