.cardContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .cardTitle {
        color: var(--text-primary, #27273F);
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .card {
        padding: 20px 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        border-radius: 8px;
        background: var(--bg-primary, #FFF);
        box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.05);
    }
}
