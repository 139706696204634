@import '../../../../styles/variables';

.wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
    @include for-tablet {
        grid-gap: 16px;
    }
}

.button {
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 9px;
    background-color: transparent;
    border: 2px solid $green;
    border-radius: $borderRadiusMiddle;
    width: 100%;
    color: #000000;
    @include for-mobile-only{
        padding: 9px 4px;
    }

    &:active {
        border: 2px solid $green;
    }

    &--active {
        background-color: rgba(76, 175, 80, 0.15);
    }

    span {
        font-size: 13px;
        line-height: 13px;
        color: $colorSecondary;
        margin-top: 4px;
    }

    &:disabled {
        background: rgba(135, 139, 144, 0.3);
        color: white;
        pointer-events: none;
        border-color: transparent;
        span {
            color: white;
        }
    }
}
