@import '../../../../../styles/variables';

.card {
    padding: 30px 24px 30px 20px;
    background-color: $bgSecondary;
    box-shadow: $shadowPrimary;
    border-radius: 8px;

    &,
    div {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    img {
        &:first-child {
            width: 48px;
            margin-right: 24px;
        }
        &:last-child {
            cursor: pointer;
            width: 24px;
            margin-left: 24px;
        }

        height: auto;
    }

    b {
        display: block;
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 10px;
    }

    span {
        font-size: 14px;
        line-height: 20px;
        color: $colorSecondary
    }

    p {
        input {
            display: block;
            margin-bottom: 10px;
            border: 1px solid $borderPrimary;
            border-radius: 4px;
            height: 28px;
            width: 160px;
            padding: 0 8px;

            &::placeholder {
                font-size: 14px;
            }
        }
    }

    label {
        input {
            cursor: pointer;
            height: 24px;
            width: 24px;
            border: 1.5px solid $colorSecondary;
            border-radius: 50%;

            &:checked {
                background: url(../../../../../img/success.svg) center;
                background-size: 125%;
                border-color: green;
            }
        }
    }
}
