@import './variables';

body {
    font-family: Muller, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif !important;
    font-size: 16px !important;
    line-height: 24px !important;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background-color: $bgPrimary!important;
    color: $colorPrimary !important;
    min-width: 320px;
    a{
        color: $green;
    }
}

#root-tooltip,
#root-modal {
    position: relative;
    z-index: 20;
}

.container {
    overflow: hidden;
    padding: 98px 20px 0;

    @include for-tablet {
        padding: 98px 40px 0;
    }

    @include for-tablet-land {
        padding: 110px 40px 0 340px;
        max-width: 1440px;
    }

    @include for-wide {
        padding: 110px 0 0;
        margin: 0 auto;
        max-width: 1060px;
    }
}
div[class^="Snackbar_snackbar-wrapper"],
div[class*=" Snackbar_snackbar-wrapper"] {
    z-index: 9999 !important;
    white-space: pre-wrap;
    margin: 85px auto;
    @include for-mobile-only{
      padding: 0 20px;
    }
}
div[class*="Snackbar_snackbar-wrapper-top-right"]  {
    z-index: 40;
    white-space: pre-wrap;
    margin: 85px auto;
    max-width: 1440px;
    @include for-mobile-new{
        margin: 85px auto;
        justify-content: center;
    }

    @include for-tablet-land {
        max-width: 1440px;
        padding-right: 40px;
        justify-content: flex-end;
    }

    @include for-notebook {
        max-width: 1440px;
        padding-right: 40px;
    }

    @include for-desktop {
        max-width: 1440px;
        margin: 85px auto 0 0;
        padding-right: 40px;

    }
    @include for-wide {
        max-width: 1060px;
        margin: 85px auto;
        padding-right: 0;
    }

}
body {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: bold;
    }
    ol, ul, dl {
        margin-top: 0;
        margin-bottom: 0;
    }
    p{
        margin-top: 0;
        margin-bottom: 0;
    }
}
