@import '../../../styles/variables';

$maxWidth: 280px;

.card {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: $bgSecondary;

    @include for-desktop {
        flex-direction: row;
    }

    .offerNumber,
    .cardLabel {
        display: inline-flex;
        color: #6E7491;
        margin-bottom: 4px;
    }

    .offerNumber {
        @include for-desktop {
            margin-bottom: 8px;
        }
    }

    .cardInfo {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 20px;

        b {
            font-size: 36px;
            line-height: 36px;
            font-weight: 500;
            color: #27273F;
        }

        @include for-desktop {
            flex-direction: row;
            flex: 1;
            justify-content: space-between;
            padding: 24px 60px 24px 24px;
        }
    }

    .statusWrapper {
        display: flex;
        flex-direction: column;
        padding: 4px;
        gap: 4px;

        .status {
            padding: 16px;
            background: #878B901A;
            border-radius: 8px 8px 0 0;

            @include for-tablet {
                &>* {
                    max-width: $maxWidth;
                }
            }
        }

        .btnWrapper {
            display: flex;
            flex-direction: column;
            padding: 16px;
            gap: 4px;
            background: #4CAF501A;
            border-radius: 0 0 8px 8px;

            @include for-tablet {
                &>* {
                    max-width: $maxWidth;
                }
            }
        }

        &.status--green {

            .status,
            .btnWrapper {
                background: #4CAF501A;
            }

            .statusText {
                color: $green;
            }
        }

        &.status--orange {

            .status,
            .btnWrapper {
                background: #FFB80026;
            }

            .statusText {
                color: $orange;
            }
        }

        &.status--red {

            .status,
            .btnWrapper {
                background: #FF3F421A;
                border-radius: 8px;
                width: 100% !important;
            }

            .statusText {
                color: $red;
            }
        }

        @include for-desktop {
            flex-direction: row;
            flex: 1;

            .status {
                width: 220px;
                flex-shrink: 0;
                border-radius: 8px 0 0 8px;
                padding: 20px;
            }

            .btnWrapper {
                flex: 1;
                border-radius: 0 8px 8px 0;
                padding: 20px;
            }
        }
    }

    button {
        height: 50px;
    }

    .gift {
        display: flex;
        align-items: flex-start;
        gap: 16px;
        padding-bottom: 8px;
    }

    .increaseText {
        margin-top: 8px;
    }
}

.loanModal {

    a {
        color: $green;
    }

    p {
        font-size: 18px;
        color: $colorPrimary;
    }

    h4 {
        margin: 24px 0;
    }

    hr {
        position: relative;
        width: 150%;
        left: -50px;
        margin-top: 20px;
    }

    ul {
        margin-bottom: 32px;

        li {
            list-style: none;
            margin-left: 20px;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                left: -17px;
                top: 8px;
                border-radius: 50%;
                width: 6px;
                height: 6px;
                background: $green;
            }
        }
    }

    label {
        margin-bottom: 24px;
    }

    input[type=checkbox] {
        margin-bottom: 32px;
    }

    .modalSpacer {
        margin: 24px 0;
    }

    .modalHalfSpacer {
        margin-top: 24px;
    }

    .modalText {
        font-size: 16px;
        white-space: pre-wrap;
    }

    .modalTextWrapper {
        .modalText p {
            font-size: 16px;
            white-space: pre-wrap;
            margin-bottom: 10px;
        }
    }

    .modalInfoDate {
        font-weight: bold;
    }

    .modalNote {
        padding: 32px;
        background: $borderSecondary;
        border-radius: 8px;
        margin-top: 24px;

        p {
            margin-bottom: 16px;
        }
    }

    .modalInfoTextWrapper {
        margin: 24px 0;
        display: flex;
        flex-direction: column;

        p:not(:last-child) {
            margin-bottom: 2px;
        }
    }

    .modalTitle {
        font-size: 14px;
        color: $colorSecondary;
        margin-bottom: 5px;
    }

    .modalPhone {
        margin: 32px 0;
    }

    .modalInput {
        margin: 24px 0;

        p {
            margin-top: 12px;
        }
    }

    .buttonWrapper {
        display: grid;
        grid-gap: 16px;
        grid-template-columns: 1fr 1fr;
    }


    .photosWrapper {
        &>div {
            margin-bottom: 24px;
        }

        label {
            margin-bottom: 0;
        }
    }

    .title {
        margin-bottom: 24px;
        font-size: 18px;
        line-height: 24px;
        font-weight: bold;
    }

    .errorText {
        font-size: 16px;
        color: $red;
    }

    .modalLabel {
        display: inline-flex;
        color: #6E7491;
        margin-bottom: 4px;
    }
}

.increaseModal {
    .sliderWrapper {
        background: #F6F8FA;
        border-radius: 12px;
        padding: 8px 12px 0;
        margin: 24px 0 0;
    
        .sliderInput {
            border: 0 !important;
            background: transparent;
            box-shadow: none;
            width: 100%;

            :global(.ant-input-number-handler-wrap) {
                display: none !important;
            }
    
            input {
                padding-left: 0;
                color: #0B0D14;
                font-size: 18px;
                line-height: 24px;
                font-weight: 500;
            }
        }
        
        .slider {
            margin: 16px 0 48px 0;
            padding-bottom: 0;
            height: 8px;

            :global(.ant-slider-step) {
                display: none !important;
            }

            :global(.ant-slider-track) {
                background-color: $green;
            }
            
            :global(.ant-slider-handle) {
                width: 24px;
                height: 24px;
                border-color: $green;
                box-shadow: none;
                margin-top: -10px;
            }
        }
    }

    button {
        height: 50px;
    }
}


