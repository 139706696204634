@import '../../../styles/variables';

.subject {
    padding-top: 48px;

    h2 {
        margin-bottom: 24px;
    }

    article {
        position: relative;
        display: grid;
        grid-gap: 20px;

        img {
            position: absolute;
            top: 0;
            right: 0;
            width: 36px;
            height: 36px;
        }

        p {
            padding-right: 40px;
        }

        span {
            display: block;
            margin-bottom: 10px;
            color: $colorSecondary;
            font-size: 14px;
            line-height: 20px;
        }

        b {
            font-size: 16px;
            line-height: 20px;
        }
    }

    @include for-tablet {
        article {
            grid-gap: 0;
            grid-template-columns: repeat(2, 300px);
        }
    }
}
