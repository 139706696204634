@import "../../../../../styles/variables";

.drawerContent {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .sectionButton {
        display: flex;
        height: 50px;
        padding: 12px 40px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 4px;
        background: var(--button-primary-active, #4CAF50);
        color: var(--button-primary-content, #FFF);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        /* 125% */
        width: 100%;

        @include for-mobile-only {
            margin-top: 0;
        }
    }

    .sectionButtonMargin {
        margin-top: 7px;
    }


    section {
        display: flex;
        flex-direction: column;
        gap: 32px;

        .sectionHeader {
            color: var(--text-primary, #27273F);
            /* Headline/H3 */
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px;
            /* 125% */
        }

        .sectionContent {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }


    }
}

.statusCard {
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;

    &>div>div>div>div {
        padding-left: 0;
    }

    &__name {
        padding: 5px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__title {
        color: var(--text-primary, #27273F);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 144.444% */
    }

    &__body {
        color: var(--text-primary, #27273F);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 150% */
        margin-bottom: 0;
    }

    &__description {
        color: var(--text-primary, #27273F);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 142.857% */
    }
}

.step {
    display: flex;
    align-items: flex-start;
    gap: 16px;

    &__icon {}

    &__body {
        display: flex;
        flex-direction: column;
        gap: 10px;

        &__title {
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 26px;
            /* 144.444% */
            margin-bottom: 0;

            p {
                margin-bottom: 0;
            }
        }

        &__info {
            color: var(--text-secondary, #6E7491);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            /* 142.857% */
        }
    }
}

.faqCard__wrapper>div>div {
    padding-top: 0;
}

.bold {
    font-weight: bold;
}

.block {
    display: block;
}

.statusCard__collapseWrapper {
    width: 100%;

    &>div>div {
        font-size: 18px;
    }
}
