@import '../../styles/variables';

.login {
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .form {
    background: $bgSecondary;
    padding: 40px 0;

    h2 {
      margin-bottom: 24px;
    }

    p {
      margin-bottom: 40px;
    }

    .input,
    .errorMessage,
    .button {
      margin-bottom: 24px;
    }

    .errorMessage {
      color: $red;
    }

    .button {
      width: 100%;
    }
    .policyAgreement{
        margin-bottom: 24px;
    }
    .loginFooter {
      display: flex;
      flex-direction: column;
      text-align: center;
      span {
        display: inline-block;
        margin-bottom: 16px;
      }
    }
  }

  .hello {
    img {
      width: 60px;
      height: 60px;
      margin-bottom: 20px;
      animation: hi 2s;
    }

    h1,
    p {
      margin-bottom: 32px;
    }

    .button + .button {
      margin-top: 16px;
    }
  }

  .policy {
    padding: 21px 0 20px;
    font-size: 14px;
    line-height: 20px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: -1px;
      left: 6.25%;
      right: 6.25%;
      height: 1px;
      background-color: $bgSecondary;
    }

    p {
      margin-bottom: 12px;
      & a {
          color: $green;
          &:hover{
            color: $green;
          }
      }
    }

    .links {
      a {
        display: block;
        color: $colorSecondary;

        + a {
          margin-top: 10px;
        }
      }
    }

    address {
      font-style: normal;
      margin: 30px 0 24px;
    }

    img {
      width: 194px;
    }
  }

  .message {
    position: fixed;
    top: 20px;
    right: 20px;
    width: calc(100% - 40px);
    max-width: 400px;
    z-index: 1;
  }


  @include for-mobile-only {
    margin-top: -58px;
    padding-top: 40px;
    background: $bgSecondary;

    .spacer {
      padding: 0 20px;
    }

    .hello {
      padding: 40px 0;
    }

    .policy {
      img {
        margin: 0 auto;
      }
    }

    .hello,
    .policy {
      background-color: $bgPrimary;
    }
  }

  @include for-mobile-only {
    .logo {
      padding-top: 40px;
    }
  }

  @include for-tablet {
    background-color: $bgPrimary;

    .wrapper {
      min-height: 100vh;
      padding: 40px 5.2% 0;
    }

    .part {
      padding: 20px 0;
    }

    .form,
    .hello {
      display: inline-block;
      vertical-align: top;
    }

    .form {
      margin-right: 40px;
      width: 360px;
      padding: 32px;
      border-radius: 16px;
      box-shadow: $shadowPrimary;
    }

    .hello {
      width: calc(100% - 400px);

      img {
        width: 100px;
        height: 100px;
      }
    }

    .policy {
      &::before {
        left: 0;
        right: 0;
      }

      p {
        max-width: 712px;
        font-size: 16px;
        line-height: 24px;
      }

      .reverse {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-end
      }

      address {
        margin: 24px 0;
        white-space: nowrap;
      }

      .links {
        display: flex;

        a + a {
          margin: 0 0 0 24px;
        }
      }
    }
  }

  @include for-tablet-land {
    .form {
      width: 460px;
      padding: 50px;
    }

    .hello {
      width: calc(100% - 500px);

      .button {
        width: 200px;
        display: inline-block;
        vertical-align: top;

        + .button {
          margin: 0 0 0 16px;
        }
      }
    }
  }

  @include for-desktop {
    .wrapper {
      margin: 0 auto;
      max-width: $maxContainer;
    }

    .form {
      margin-right: 100px;
    }

    .hello {
      width: 530px;

      .button {
        width: 218px;
      }
    }

    .policy {
      .flex {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }
    }
  }
}

@keyframes hi {
  0%,
  100% {
    transform: rotate(0);
  }
  30% {
    transform: rotate(10deg);
  }
  70% {
    transform: rotate(-10deg);
  }
}
