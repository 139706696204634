@import '../../../styles/variables';

.logo {
  display: block;
  background-repeat: no-repeat;
  background-position: center center;

  &.default {
    width: 40px;
    height: 40px;
    background-image: url(./img/logo-mobile.svg);

    //background-image: url(./img/Logo_Mobile_new_year.svg);
    //background-size: contain;
  }

  &.big {
    width: 214px;
    height: 54px;
    background-image: url(./img/logo.svg);

    //background-image: url(./img/Logo_new_year.svg);
    //background-size: contain;
  }

  @include for-tablet-land {
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.9;
    }

    &.default {
      width: 214px;
      height: 54px;
      background-image: url(./img/logo.svg);

      //background-image: url(./img/Logo_new_year.svg);
      //background-size: contain;
    }
  }
}
