@import '../../../styles/variables';

.burger {
    display: inherit;
    position: relative;
    background-color: transparent;
    width: 24px;
    height: 24px;

    span {
        position: absolute;
        height: 2px;
        width: 20px;
        border-radius: 1px;
        background-color: $colorPrimary;
        left: 2px;
        top: 50%;
        transform-origin: left;
        transition: 0.2s ease;
        transition-property: opacity, transform;

        &:nth-child(1) {
            transform: translateY(-8px);
        }

        &:nth-child(2) {
            transform: translateY(-50%);
        }

        &:nth-child(3) {
            transform: translateY(6px);
        }
    }

    &.active {
        span {
            &:nth-child(1) {
                transform: rotate(-45deg) translateY(7px) translateX(-3px);
            }

            &:nth-child(2) {
                opacity: 0;
                transform: translateX(5px);
            }

            &:nth-child(3) {
                transform: rotate(45deg) translateY(-7px) translateX(-3px);
            }
        }
    }
}
