@import "../../../styles/variables";

.errorContainer {
    background: $bgSecondary;
    padding: 40px 0;
    position: relative;
    box-shadow: $shadowSecondary;

    &__wrapper {
        @include for-mobile-only {
            padding: 0 5.55%;
        }
    }

    &.hasError{
        animation-duration: 5s;
        animation-name: errorPulse;
    }

    & + & {
        margin-top: 16px;
    }

    @include for-mobile-only {
        margin: 0 -7.14% 0 -7.14%;
    }

    @include for-tablet {
        border-radius: $borderRadiusLarge;
        padding: 40px 30px;
    }
}
@keyframes errorPulse{
    from {
        //border: 1px solid $borderPrimary;
    }
    50%{
        //border: 1px solid $red;
        box-shadow: 1px 1px 20px $red;
    }
    to {
        //border: 1px solid $borderPrimary;
    }
}
