.wrapper {
    position: relative;
    display: inline-block;
}

.animatedSvg {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: -1;
}

.text {
    white-space: nowrap;
}
