@import '../../../styles/variables';

.range {
  width: 100%;
  height: 4px;
  cursor: pointer;
  position: relative;
  background-color: $borderDark;
  border-radius: 8px;


  &::before {
    content: '';
    position: absolute;
    top: -12px;
    bottom: -24px;
    left: -12px;
    right: -12px;
  }

  &__progress {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: $green;
    border-radius: 8px;
    transform-origin: left;
    transition: ease transform;

  }

  &__button {
    cursor: pointer;
    pointer-events: none;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: $green;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    border: none;
    padding: 0;
    transition: ease left;
  }
}
.disabled {
    opacity: 0.85;
    pointer-events: none;
    background-color: rgba(242, 242, 242, 1);
}
