@import '../../../styles/variables';

.wrapperChildren {
  position: relative;
}

.tooltip {
  position: fixed;
  max-width: 388px;
  box-shadow: 2px 8px 24px 8px rgba(0, 0, 0, 0.1);
  border-radius: $borderRadiusMiddle;

  &--top {
    .tooltip__arrow {
      transform: rotate(180deg);
      bottom: -6px;
    }
  }

  &--bottom {
    .tooltip__arrow {
      top: -6px;
    }
  }

  &__content {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    padding: 16px;
    width: 100%;
    border-radius: $borderRadiusMiddle;
    background-color: $bgSecondary;
  }


  &__arrow {
    position: absolute;
    right: 24px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent $bgSecondary transparent;
  }

}


