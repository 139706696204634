@import '../../styles/variables';

.news {
    article {
        margin-top: 24px;

        span {
            font-size: 14px;
            line-height: 20px;
            color: $colorSecondary;

            &.green {
                color: $green;
            }
        }

        h2 {
            margin: 16px 0 24px;
        }
    }

    &__share {
        margin-top: 32px;
    }
    h2 +img {
      width: 100% !important;
      height: auto!important;
    }
}
