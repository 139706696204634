@import '../../../styles/variables';

.ap {
    padding-top: 48px;

    h2,
    &__tag,
    &__tooltip {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 0;
    }

    &__tag {
        margin: 0 10px;
    }

    &__block {
        margin-top: 24px;
    }

    @include for-tablet {
        padding-top: 60px;
    }
}
