@import "../../../styles/variables";

.settings {
  &__group {
    display: grid;
    grid-gap: 24px;
  }

  &__title {
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
  }

  &__auto {
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    display: inline-block;
    vertical-align: top;

    &_disabled {
      pointer-events: none;
      opacity: 0.25;
    }

    & + & {
      margin-left: 16px;
    }
  }

  &__available {
    font-size: 18px;
    line-height: 26px;
    color: $green;
    font-weight: 600;
    @include for-mobile-only{
      border-radius: 4px;
      border: 1px solid $green;
      padding: 10px 10px 10px 18px;
      position: relative;
      span {
        position: absolute;
        top: -10px;
        left: 13px;
        border-radius: 50%;
        background-color: #fff;
        padding: 0 5px;
        color: $green;
      }
    }
  }

  &__minor {
    font-size: 13px;
    line-height: 18px;
    color: $colorSecondary;
    font-weight: 500;

  }
  &__accept{
    p {
      white-space: pre-wrap;
    }
  }

  &__slider {
    margin: 0 0 -8px;
    & div, & input {
      font-size: 18px;
    }
  }

  &__more {
    color: $green;
    cursor: pointer;
    text-align: center;
    margin-top: 10px;
  }

  &__duration {
    max-width: 570px;
    margin-bottom: 8px;
     & > p {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 12px;
    }
  }
  &__checkboxWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
    @include for-mobile-only {
      display: grid;
      grid-template-columns: 1fr;
      gap: 24px;
      grid-gap: 24px;
    }
  }
  &__checkbox {
    display: flex;
    align-items: center;
    label{
      margin-right: 10px;
    }
  }

  &__button {
    button{
      @include for-mobile-only{
        width: 100%;
      }
    }
    p {
      display: flex;
      align-content: center;
      justify-content: center;
      color: $green;
      align-self: center;
      cursor: pointer;
      width: fit-content;
      @include for-mobile-only{
        width: 100%;
      }
    }
  }

  &__calc {
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-gap: 8px 20px;
    @include for-mobile-only {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    p {
      font-size: 14px;
      line-height: 20px;
      color: $colorSecondary;
      margin-bottom: 10px;
      @include for-mobile-only{
        margin-bottom: 0;
      }
    }

    h4 {
      font-size: 30px;
      line-height: 40px;
      display: flex;
      align-items: center;
    }
    .svgWrapper{
      margin-bottom: -1px;
      @include for-mobile-only {
        margin-bottom: 0;
      }
    }

  }
  &__select{
    margin-top: 20px;
  }

  &__hide {
    opacity: 0;
    display: none;
  }

  @include for-mobile-only {
    &__calc {
      grid-template-columns: 140px 1fr;
    }
    &__button{
      width: 100%;
      p{
        margin-top: 10px;
      }
    }
    &__checkbox {
      gap: 8px;
    }
  }

  @include for-mobile-new {
    &__calc {
      grid-template-columns: 140px 1fr;
    }
  }

  @include for-tablet {
    &__button {
      width: fit-content;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 25px;
    }
  }

  @include for-tablet-land {
    &__button {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 25px;
    }
    &__group {
      grid-gap: 20px 24px;
      grid-template-columns: repeat(3, 1fr);
      align-items: center;
    }

    &__title,
    &__subjects,
    &__accept,
    &__message,
    &__duration,
    &__calc {
      grid-column-start: 1;
      grid-column-end: 4;
    }

    &__select {
      grid-column-start: 1;
      grid-column-end: 2;
    }

    &__available {
      grid-column-start: 2;
      grid-column-end: 4;

      span {
        display: block;
      }
    }

    &__minor,
    &__slider {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    &__slider {
      margin: 0 0 8px;
    }
  }
}
.changeOfferModal{
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;

}
.moreMoneyModal{
  p {
    margin-bottom: 24px;
  }
  a{
    color: $green;
  }
}
