@import '../../../styles/variables';

.slider {
  &__main {
    position: relative;
  }

  &__input {
    height: 50px;
    width: 100%;
    padding: 0 18px 24px 0;
    text-align: right;
    background-color: transparent;
    font-size: 18px!important;
    border: none;
    @include for-mobile-only {
      font-size: 18px!important;
    }
  }
  ::-webkit-input-placeholder {
    color:white;
  }

  :-moz-placeholder { /* Firefox 18- */
    color:white;
  }

  ::-moz-placeholder {  /* Firefox 19+ */
    color:white;
  }

  :-ms-input-placeholder {
    color:white;
  }

  &__input,
  &__placeholder {
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
  }

  &__placeholder {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__currency {
    position: absolute;
    right: 2px;
    top: 2px;
  }

  &__slidebar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: $bgInverse;
    border-radius: 4px;
  }

  &__description {
    margin-top: 11px;
    display: -webkit-flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 14px !important;
    line-height: 20px;
    color: $colorSecondary;
  }

  @include for-tablet {
    &__input,
    &__placeholder {
      font-size: 21px;
      line-height: 32px;
    }
  }
}
