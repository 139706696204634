@import '../../../styles/variables';

.autoPayment {
  display: inline-block;
  position: relative;
  margin: 0 !important;

  img {
    position: absolute;
    top: 2px;
    left: 0;
  }

  span {
    color: $green !important;
    font-size: 16px !important;
    line-height: 24px !important;
    padding-left: 20px;
    margin: 0 !important;
  }

  @include for-tablet {
    span {
      padding-left: 24px;
    }
  }
}
