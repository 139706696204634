@import "../../../styles/variables";

.banner {
    h2 {
        margin-bottom: 24px;
    }

    article {
        svg {
            display: block;
            width: 234px;
            margin: 0 auto;
        }

        h3 {
            font-size: 21px;
            line-height: 26px;
            margin-bottom: 24px;
        }

        button {
            margin-top: 24px;
        }
    }

    @include for-mobile-only {
        article {
            svg {
                margin-bottom: 30px;
            }
        }
    }

    @include for-tablet {

        h2 {
            margin-bottom: 32px;
        }

        article {
            display: flex;
            align-items: center;

            svg {
                min-width: 200px;
                width: 271px;
                margin-right: 48px;
            }

            ul {
                padding-left: 28px;
            }

            button {
                width: auto;
            }
        }
    }
}
