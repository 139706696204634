@import "../../../styles/variables";

.privacy {
    &__head {
        margin-bottom: 24px;
    }

    &__signed{
      position: relative;
      margin-top: 10px;
      color: $green;
      display: flex;
      align-items: center;
      img {
        margin-right: 8px;
        margin-bottom: 3px;
      }
    }

    &__signature {
         h6 {
             font-size: 18px;
             line-height: 26px;
             margin-bottom: 24px;
         }

        button {
            margin-top: 16px;
            width: fit-content;
        }
    }

    &__modal {
        display: grid;
        grid-gap: 32px;

        &_checkboxes {
            display: grid;
            grid-gap: 24px;
        }

        &_status {
            display: flex;

            b {
                color: $green;
                width: 240px;
                margin-right: 10px;
                cursor: pointer;
                margin-bottom: 16px;
            }

            span {
                font-size: 14px;
                line-height: 20px;
                color: $colorSecondary;
            }
        }
    }
}
.textCode {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
}

.phone {
    white-space: nowrap;
}
