.error {
    padding-top: 100px;
    text-align: center;
    min-height: 50vh;

    h2 {
        font-size: 112px;
        line-height: 112px;
        margin-bottom: 32px;
    }

    p {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 24px;
    }

    div {
        margin: 0 auto;
        width: 200px;
    }
}
