@import '../../../styles/variables';

.tabs {
  position: relative;

  &.divider {
    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 1px;
      width: 100%;
      height: 1px;
    }

    &--white {
      &::before {
        background-color: $bgSecondary;
      }
    }

    &--gray {
      &::before {
        background-color: $borderPrimary;
      }
    }
  }

  &.size {
    &--default {
      .tabs__item {
        font-size: 16px;
      }
    }
    &--big {
      .tabs__item {
        font-size: 24px;
        font-weight: bold;
      }
    }
  }

  &.viewActive {
    &--text {
      .tabs__item {
        &:not(:first-child) {
          margin-left: 32px;
        }
        &.active {
          color: $green;
          &::before {
            background-color: transparent;
          }
        }
      }
    }
  }



  &__item {
    position: relative;
    display: inline-block;
    cursor: pointer;
    line-height: 24px;
    padding-bottom: 16px;
    color: $colorSecondary;
    transition: 0.3s ease color;
    @include for-mobile-only{
      font-size: 15px;
    }

    &::before {
      content: '';
      position: absolute;
      top: calc(100% - 2px);
      left: 0;
      width: 100%;
      height: 2px;
      border-radius: 10px;
      background-color: transparent;
      transition: 0.3s ease background-color;
    }

    b {
      margin-left: 12px;
    }

    & + & {
      margin-left: 40px;
      @include for-mobile-only{
        margin-left: 15px;
      }
    }

    &.active {
      color: $colorPrimary;

      b {
        color: $green;
      }

      &::before {
        background-color: $green;
      }
    }
  }

  @include for-mobile-only {
    &__list {
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      margin: 0 -7.14%;
      padding: 0 7.14%;
      scroll-padding: 0 7.14%;
      scroll-snap-type: x mandatory;
    }

    &__item {
      scroll-snap-align: start;
      scroll-snap-stop: always;
    }
  }

  @include for-tablet-land {
    &__item {
      &:hover {
        color: $colorPrimary;
      }
    }
  }
}
