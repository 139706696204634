.ant-drawer-title{
    font-family: Muller;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: left;
}
.ant-drawer-header{
    border-bottom: none;
    padding: 24px 24px 0;
}
