@import '../../../styles/variables';

.modal {
    p {
        margin-bottom: 40px;
    }

    img {
        vertical-align: middle;
    }

    &__input {

        &:not(:last-child) {
            margin-bottom: 24px;
        }

        &--small {
            width: 50%;
            display: inline-block;
            vertical-align: middle;
            margin-right: 24px;
        }
    }

    &__errorMessage {
        color: $red;
        margin-top: 10px;
    }

    &__btn {
        margin-top: 40px;
    }

    &__tabs {
        margin-bottom: 40px;
    }
}


.name {
    display: none;
}
