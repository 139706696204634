@import "../../../styles/variables";

.info {
  display: flex;
  flex-direction: column;

  &__label {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;
    color: #878b90;
    font-style: normal;
    font-weight: 400;
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
    color: $colorPrimary;
    font-weight: bold;
    margin-right: 3px;
    &--overflow {
      white-space: nowrap;
      max-width: calc(100% - 40px);
      overflow: hidden;
      text-overflow: ellipsis;
    }
    @include for-tablet {
      font-size: 18px;
      line-height: 26px;
    }
  }
  &__wrapperText{
    display: flex;
    align-items: baseline;
     svg{
         margin-right: 5px;
     }
    i {
      display: inline-flex;
      margin: 0;
      cursor: pointer;
    }
  }

}
