@import '../../../styles/variables';

.wrapper {
  display: flex;
  flex-direction: column;

  &--partial {
    .message {
      order: 1;
      margin-bottom: 40px;
    }

    .title {
      order: 2;
      margin-bottom: 40px;
    }

    .input {
      order: 3;
      margin-bottom: 16px;
    }

    .buttonsSelection {
      order: 4;
    }

    .collapse {
      order: 5;
    }

    .titleData {
      order: 6;
    }

    .cards {
      order: 7;
    }

    .emailWrapper{
      order: 8;
    }

    .checkbox {
      order: 9;
    }

    .button {
      order: 10;
    }
  }
}

.sub {
  font-size: 18px;
  line-height: 26px;
  color: $green;
  margin-bottom: 32px;
}

.input {
  order: 1;

  &__text {
    font-size: 14px;
    line-height: 20px;
    color: $colorSecondary;
    margin-top: 16px;
  }
}

.collapse {
  margin: 32px 0 40px;
  order: 2;
}

.titleData {
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  order: 3;
  margin-bottom: 24px;
}

.cards {
  margin: 0 0 32px;
  order: 4;
}

.emailWrapper {
    margin-bottom: 32px;
    order: 5;
    position: relative;
    .emailError {
        position: absolute;
        color: $red;
    }
}
.inputMfoVi{
    margin-bottom: 24px;
}

.message {
  margin-bottom: 24px;
  order: 6;
}

.checkbox {
  margin-bottom: 40px;
  order: 7;
}

.button {
  order: 8;
  button {
    span {
      span {
        font-size: 18px !important;
        line-height: 18px !important;
        padding-top: 2px;
        span {
          font-size: 18px !important;
          line-height: 18px !important;
          opacity: 0.6;
        }
      }
      div {
        font-size: 13px !important;
        opacity: 0.6;
      }
    }
  }
}

.red {
  color: $red;
}

.green {
    color: $green;
}






