@import '../../../../styles/variables';

.faq {
    padding-top: 48px;
    padding-bottom: 5px;

    h2 {
        margin-bottom: 24px;
    }

    @include for-tablet {
        padding-top: 60px;

        h2 {
            margin-bottom: 32px;
        }
    }

    .wrapper {
        background-color: $bgSecondary;
        box-shadow: $shadowPrimary;
        border-radius: $borderRadiusLarge;
        padding: 0 32px;

        & > div > div > div {
            padding-left: 0;
        }

        @include for-mobile-only {
            padding: 0 24px;
        }
    }
}
