@import '../../../styles/variables';

.baseLine {
    width: 100%;
    height: 4px;
    background-color: $bgPrimary;
    position: relative;
    margin-bottom: 8px;
}

.fillLine {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: $orange;
    border-radius: $borderRadiusSmall;
    transition: width .3s;
}

.text {
    font-size: 13px;
    line-height: 18px;
    color: $colorSecondary;
}
