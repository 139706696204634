.userAgreement {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    margin-top: 15px;
}

.footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 25px;
}

.checkboxWrapper {
    margin-top: 25px;
}
.btnWrapper {
    margin-top: 25px;
    text-align: center;
}
