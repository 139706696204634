@import '../../../../../styles/variables';
.card {
    padding: 20px 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-radius: 8px;
    background: var(--bg-primary, #FFF);
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.05);

    .card__title {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .card__header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .card__titleText {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }
            .card__status{
                display: flex;
                gap: 10px;
            }
            .card__questionIcon {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                border-radius: 15px;
                width: 24px;
                height: 24px;
                color: gray;
                text-align: center;
                cursor: pointer;
            }
        }

        .card__subtitle {
            font-size: 36px;
            font-style: normal;
            font-weight: 500;
            line-height: 40px;
        }
    }
    .card__hr{
        /* Line */

        width: 100%;
        height: 1px;

        /* BG/Divider */
        background: #EAEDF0;
        border-radius: 10px;

    }
    .card__cell {
        display: flex;
        justify-content: space-between;
        align-items: center;


        .card__cellKey {
            display: flex;
            justify-content: flex-start;
            gap: 5px;

            .card__cellIcon {
                width: 24px;
                height: 24px;
            }

            .card__cellTitle {
                align-self: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
            }
        }

        .card__cellValue {
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px
        }
    }

    .cardButton__wrapper{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
        height: 50px;
        button{
            height: 100%
        }
        @include for-mobile-only{
            display: flex;
            height: 40px;
        }
    }
    .cardButtonClub__wrapper{
        display: flex;
        gap: 10px;
        @include for-mobile-only{
            flex-wrap: wrap;
        }
        & > * {
            flex-grow: 1;
        }
    }
}

.inviteModal{
    display: flex;
    flex-direction: column;
    gap: 16;
    background: white;
    border-radius: 16px;
    
    .inviteModal__row {
        background: #EFFBEF;
        border-radius: 12px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .inviteModal__rowTitle{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;
            font-size: 16px;
            padding: 0;
            margin: 0;
        }
        
        .inviteModal__text{
            color: #608185;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            padding: 0;
            margin: 0;
        }

        .inviteModal__container {
            display: flex;
            flex-direction: row;
            gap: 12px;
            margin: 16px 0 0 0;

            @media (max-width: 576px) {
                flex-direction: column;
            }
            
            .inviteModal__link{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 14px 18px;
                border-radius: 4px;
                background: white;
                color: var(--text-primary, #27273F);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 142.857% */
                width: 100%;
            }
        }
    }
    
    .inviteModal__divider{
        position: relative;
        text-align: center;
        padding: 16px;

        .inviteModal__dividerText {
            font-size: 18px;
            color: #4B66C6
        }

        .inviteModal__dividerText::before {
            content: "";
            position: absolute;
            width: 40%;
            top: 50%;
            left: 0;
            right: 0;
            height: 1px;
            background-color: #DBDBDB;
        }

        .inviteModal__dividerText::after {
            content: "";
            position: absolute;
            width: 40%;
            top: 50%;
            left: 60%;
            right: 0;
            height: 1px;
            background-color: #DBDBDB;
        }
    }
}

.card__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 12px 40px;
    color: white;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border-radius: 4px;
    background: #4CAF50;
    cursor: pointer;

    p {
        margin: 0;
    }
}
.icon__wrapper{
    border-radius: 10px;
    border: 1px solid $green;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
