@import '../../styles/variables';

.wrapper{
    padding-bottom: 60px;
    @include for-mobile-only{
        padding-bottom: 48px;
    }
    h2.title {
        font-size: 20px;
        line-height: 24px;

    }
    h2 {
        margin-bottom: 24px;
    }
}

.loans {
    position: relative;

    &.padding {
        padding-top: 48px;
    }

    h1 {
        margin-bottom: 32px;
    }

    &__tabs {
        margin-bottom: 24px;
    }

    &__auto {
        position: absolute;
        right: 0;
    }

    @include for-mobile-only {
        position: relative;

        &__auto {
            top: 52px;
        }
    }

    @include for-tablet {
        &.padding {
            padding-top: 60px;
        }

        &__tabs {
            position: relative;
        }

        &__auto {
            bottom: 15px;
        }
    }
}
.modalText{
  margin-bottom: 32px;
}
