@import "../../../styles/variables";

.title {
    font-size: 24px;
    line-height: 24px;
    font-weight: bold;
    margin-bottom: 16px;
    @include for-tablet {
        margin-bottom: 0;
        margin-right: 32px;
    }
}
.titleWrapper{
    margin-bottom: 32px;
    display: flex;
    align-items: baseline;
    flex-direction: column;
    @include for-tablet {
        flex-direction: row;
    }
}
.wrapper{
    margin: 24px 0;
}

.messageWrapper {
    margin-bottom: 32px;
}
.special_equipment{
    color: $green;
    cursor: pointer;
}

.paragraph {
    margin-bottom: 10px;
}
.infoWrapper {
    display: grid;
    gap: 20px;
    margin-bottom: 24px;

    @include for-tablet-land {
        div:first-child{
            grid-column-start: 1;
            grid-column-end: 4;
        }

        grid-template-columns: repeat(3, 1fr);
    }

    .info {
        display: flex;
        flex-direction: column;

        &__label {
            margin-bottom: 10px;
            font-size: 14px;
            line-height: 20px;
            color: #878b90;
        }

        &__text {
            font-size: 16px;
            line-height: 16px;
            color: $colorPrimary;
        }
    }
}

.inputsGroup {
    display: grid;
    grid-gap: 32px 20px;
    grid-template-columns: 1fr;
    @include for-tablet-land {
        grid-template-columns: repeat(3, 1fr)
    }
}
.row {
    @include for-tablet {
        grid-column-start: 1;
        grid-column-end: 4;
    }
}

.photoWrapper {
    @extend .row;
    display: grid;
    gap: 16px;
    width: 100%;
    @include for-tablet-land {
        grid-template-columns: repeat(4, 1fr);
        gap: 32px 20px;
    }
}
.stsWrapper {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr;
    @include for-tablet-land {
        grid-template-columns: repeat(2, 1fr)
    }
}
.checkboxTitle{
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    @include for-tablet {
        font-size: 18px;
        line-height: 26px;
    }
}

.subCheckbox {
    font-size: 14px;
    line-height: 20px;
    margin-top: 16px;
    padding-left: 40px;
    @include for-tablet {
        font-size: 16px;
        line-height: 24px;
    }
}
.messageLink{
    color: $green;
}
.ptsTabs {
    display: flex;
    margin: 0 0 24px;
}
.note{
    color: $colorSecondary;
    font-size: 13px;
    line-height: 18px
}
