@import "../../../styles/variables";

.section {

    h1 {
        margin-bottom: 24px;
    }

    &__line {
        counter-reset: line-number;
        h2 {
            margin-bottom: 24px;
        }

        article {
            margin-bottom: 32px;
            display: grid;
            grid-template-columns: 70px 1fr;
            grid-gap: 16px;
            counter-increment: line-number;

            p {
                display: flex;
                align-items: center;
                b {
                    font-size: 13px;
                    line-height: 26px;
                    margin-bottom: 8px;
                    display: flex;
                    align-items: center;

                    &::before {
                        content: counter(line-number);
                        display: inline-flex;
                        font-size: 14px;
                        line-height: normal;
                        align-items: center;
                        justify-content: center;
                        min-width: 24px;
                        height: 24px;
                        border: 1.5px solid $colorPrimary;
                        border-radius: 50%;
                        margin-right: 8px;
                    }
                }
            }
        }

        button {
            width: 320px;
            padding: 0 30px;

            @include for-mobile-only {
              max-width: none;
              width: 100%;
              padding: 0;
              span {
                font-size: 14px !important;
                line-height: 100% !important;
              }
            }
        }

        ul {
            padding: 24px 20px;
            //border: 1px solid $borderPrimary;
            border-radius: 8px;
            list-style: none;
            background-color: $bgSecondary;
            box-shadow: $shadowPrimary;
            li {
                position: relative;
                padding: 4px 0 0 36px;
                font-weight: bold;
                font-size: 18px;
                line-height: 26px;
                span {
                    color: $green;
                }
                .minor{
                    display: block;
                    font-size: 13px;
                    line-height: 18px;
                    color: $colorSecondary;
                    font-weight: 500;
                }
                &::before {
                    content: "";
                    background-image: url(../../../img/success.svg);
                    background-size: 30px 30px;
                    background-position: center center;
                    width: 30px;
                    height: 30px;
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                + li {
                    margin-top: 16px;
                }
            }
        }
    }

    @include for-mobile-new {
        &__line {
            article {
                p {
                    b {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    @include for-mobile-only {
        &__line {
            article {
                p {
                    span {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }

            & + & {
                margin-top: 48px;
            }
        }
    }

    @include for-tablet {

        h1 {
            margin-bottom: 40px;
        }

        &__main {
            display: grid;
            grid-template-columns: 1fr 300px;
            grid-gap: 16px;
        }

        &__line {
            h2 {
                margin-bottom: 32px;
            }

            article {
                margin-bottom: 40px;

                p {
                    b {
                        font-size: 21px;
                    }
                }
            }
        }
    }

    @include for-notebook {
        &__main {
            grid-template-columns: 1fr 340px;
        }
    }
}
