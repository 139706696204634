@import '../../../styles/variables';

.skeleton {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  &::before {
    background-color: $bgSecondary;
  }

  &::after {
    transform: translateX(-100%);
    background: linear-gradient(90deg, transparent, rgba($green, 0.2), transparent);
    animation: ssc-loading 1.3s infinite;
  }

  @keyframes ssc-loading {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(100%);
    }
  }
}
