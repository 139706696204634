@import "../../../styles/variables";

.head {
  margin-bottom: 24px;
}

.titleWrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: 32px;
  flex-direction: column;
  .title {
    margin-bottom: 0;
  }
  @include for-tablet {
    flex-direction: row;
    margin-bottom: 24px;
    align-items: center;
  }
}

.title {
  font-size: 24px;
  line-height: 24px;
  font-weight: bold;
  margin-bottom: 32px;
  @include for-tablet {
    margin-bottom: 24px;
  }
}

.message {
  flex-grow: 1;
  margin-top: 32px;
  @include for-tablet {
    margin-left: 32px;
    margin-top: 0;
  }
}

.checkboxGroup {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
}

.insideCheckboxGroup {
  display: grid;
  grid-gap: 32px 20px;
  margin: 8px 0 8px 0;
  @include for-tablet {
    grid-template-columns: repeat(3, 1fr);
  }
}

.inputsGroup {
    display: grid;
    grid-gap: 32px 20px;
    grid-template-columns: 1fr;
    @include for-tablet {
        grid-template-columns: repeat(3, 1fr)
    }
}

.wideColumn {
  @include for-tablet {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

.afterWideColumn {
  @include for-tablet {
    grid-column-start: 1;
  }
}

.row {
  @include for-tablet {
    grid-column-start: 1;
    grid-column-end: 4;
  }
}

.photos {
  display: grid;
  gap: 16px;

  @include for-tablet-land {
    grid-template-columns: repeat(4, 1fr);
    gap: 32px 20px;
  }
}
.wrapper {
    & > div:not(:last-child){
        margin-bottom: 24px;
    }
}
.mainColor{
    color: $green;
    cursor: pointer;
    width: fit-content;
}
