@import '../../../styles/variables';

.onlineLoanBanner {
    min-height: 360px;
    background: #C9E7CB;
    border-radius: $borderRadiusLarge;
    overflow: hidden;
    padding: 30px 20px;
    position: relative;
    @include for-tablet {
        padding: 40px 50px;
    }

    &--dark {
        background: #1B1B1A;
        .listWrapper {
            color: #fff !important;
        }
        .header {
            color: #fff !important;
        }
        .circle {
            background-color: #fff !important;
        }
        .loanButton {
            background-color: $green !important;
            color: #fff;
        }
    }

    .shapeFigure {
        display: none;
        @include for-tablet {
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;

        }

        &__mobile {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            height: 100%;
            width: 100%;
            @include for-tablet {
                display: none;
            }
        }
    }

    .shapeSymbol {
        display: none;
        @include for-tablet {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;

        }

        &__mobile {
            display: block;
            position: absolute;
            bottom: -42px;
            right: 0;
            height: 100%;
            width: 100%;
            @media (min-width: 375px) {
                bottom: -22px;
            }
            @include for-tablet {
                display: none;
            }
        }
    }

    .wrapper {
        cursor: pointer;
        position: relative;
        z-index: 1;
    }

    ul{
        list-style: none;
    }

    .header {
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 30px;
        color: #4CAF50;
        span {
            display: none;
            @include for-tablet {
                display: inline;
            }
        }
        @include for-tablet {
            font-size: 48px;
            line-height: 48px;
        }
    }

    .textWrapper {
        display: grid;
        grid-template-columns: 1.1fr 0.9fr;
        grid-gap: 4px;
        margin: 20px 0 169px;
        @include for-tablet {
            display: flex;
            gap: 35px;
            margin: 24px 0;
        }
    }

    .listWrapper {
        display: flex;
        flex-direction: column;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #27273F;

        h3 {
            font-size: 18px;
            line-height: 26px;
            margin-bottom: 10px;
            text-transform: capitalize;
            span {
                display: none;
                @include for-tablet {
                    display: inline;
                }
            }
            @include for-tablet {
                text-transform: none;
                margin-bottom: 16px;
            }
        }
        li{
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            position: relative;
            font-size: 14px;
            line-height: 20px;

            @include for-tablet {
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 10px;
            }
        }

        img{
            margin-right: 3px;
        }
        .circle{
            border-radius: 50px;
            width: 6px;
            height: 6px;
            background: #4CAF50;
            margin-right: 10px;
            align-self: flex-start;
            margin-top: 6px;
            flex-shrink: 0;
            @include for-tablet {
                margin-right: 14px;
            }
        }
        .iconWrapper{
            li{
                margin-bottom: 10px;
                &:last-child {
                    align-items: flex-start;
                }
                &:not(:last-child) {
                    img {
                        margin-right: 14px;
                    }
                }
            }
        }

    }

    .loanButton{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 32px;
        gap: 8px;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        width: 100%;
        height: 50px;
        cursor: pointer;
        transition: background-color 0.3s;
        background: #FFFFFF;
        border-radius: 8px;
        color: $colorPrimary;
        @include for-tablet {
            width: 240px;
        }

        &:hover {
            background-color: $bgPrimary;
        }
   }


}
