@import "../../../styles/variables";

.title {
    margin-bottom: 24px;
    font-size: 24px;
    line-height: 24px;
    font-weight: bold;
}

.infoWrapper {
    display: grid;
    gap: 20px;
    margin-bottom: 32px;

    @include for-tablet-land {
        grid-template-columns: repeat(3, 1fr);
    }
}
.carInfoWrapper{
  @include for-tablet-land {
    div:first-child{
      grid-column-start: 1;
      grid-column-end: 4;
    }
  }
}

.actionBtn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: none;
    margin: 0;
    padding: 0;
    outline: none;

    &__text {
        display: inline-block;
        margin-left: 8px;
        font-size: 16px;
        line-height: 24px;
        color: $green;
    }
}

.infoText {
    margin-bottom: 24px;
    font-size: 13px;
    line-height: 18px;
    color: $colorSecondary;
}

.photoWrapper {
    display: grid;
    gap: 32px 20px;
    margin-bottom: 24px;

    @include for-tablet-land {
        grid-template-columns: repeat(4, 1fr);
    }
}

.text {
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 24px;
    color: $colorPrimary;
}

.checkboxWrapper {
    margin-top: 32px;
    & > div {
        padding: 16px;
        border-radius: 8px;
        background: $accept;
    }
}
.final__modal {
  display: grid;
  grid-gap: 32px;

  &_status {
    display: flex;

    b {
      color: $green;
      width: 240px;
      margin-right: 10px;
    }

    span {
      font-size: 14px;
      line-height: 20px;
      color: $colorSecondary;
    }
  }
}
