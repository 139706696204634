@import '../../styles/variables';

.sidebar {
    position: fixed;
    z-index: 15;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: $bgSecondary;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s;
    @include for-mobile-only{
      padding-top: 60px;
    }

    nav {
        padding: 72px 0 24px;
        font-size: 15px;
        @include for-mobile-only {
            padding: 0;
        }
        span{
            display: flex;
        }
    }
    @include for-mobile-only {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .link,
    .logout{
        color: $colorPrimary;
        padding: 8px 24px;
        display: flex;
        align-items: center;
        cursor: pointer;
        svg {
            margin-right: 16px;
        }
    }

    .active {
        &.link,
        .link {
            pointer-events: none;
            color: $green;

            svg {
                path,
                ellipse,
                rect {
                    stroke: $green;
                }
            }
        }

        &.fired {
            color: $red;
        }
    }

    ul {
        padding-inline-start: 60px;
        list-style: none;
    }

    hr {
        margin: 16px 0;
        border: none;
        height: 1px;
        background: #EFF3F6;
    }

    p {
        padding-left: 40px;
        font-size: 14px;
        line-height: 14px;

        a {
            svg {
                margin: 0 8px;
            }
        }
    }

    .logout {
        padding: 16px 20px;
        //background-color: $bgPrimary;
    }

    &.show {
        opacity: 1;
        pointer-events: inherit;
    }

    @include for-tablet-land {
        width: 300px;
        box-shadow: $shadowPrimary;
        opacity: 1;
        pointer-events: inherit;

        .link {
            position: relative;
            transition: color 0.3s;

            &::after {
                content: '';
                position: absolute;
                z-index: -1;
                top: 0;
                left: -80px;
                right: -20px;
                bottom: 0;
                transition: background-color 0.3s;
            }

            &:hover {
                &::after {
                    background-color: $bgPrimary;
                }
            }
        }

        &--only-mobile {
            display: none;
        }
    }

    .friends {
        &:hover {
            color: #4CAF50;

            svg {
                rect {
                    stroke: #4CAF50;
                }

                path {
                    fill: #4CAF50;
                }
            }

            &::after {
                background-color: initial;
            }
        }
    }
}
