@import '../../../../styles/variables';

.cards {
  display: grid;
  grid-gap: 10px;

  @include for-tablet {
    grid-template-columns: repeat(2, 1fr);
  }

  @include for-desktop {
    grid-template-columns: repeat(3, 1fr);
  }
}
