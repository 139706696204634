@import '../../../styles/variables';

.collapse {
  cursor: pointer;
  overflow: hidden;
  position: relative;
  label, a, p{
    cursor: pointer!important;
  }

  &--default {
    padding: 24px 0 24px 40px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 28px;
      left: 6px;
      width: 2px;
      height: 12px;
      background-color: $iconPrimary;
      transition: 0.3s ease;
    }

    &::before {
      transform: rotate(90deg);
      transition-property: background-color;
    }

    &::after {
      transition-property: background-color, transform;
    }

    &.active {
      &::after {
        transform: rotate(-90deg);
      }
    }
  }

  &--chevron {
    padding-right: 40px;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 4px;
      width: 20px;
      height: 20px;
      background-image: url(../../../img/chevron.svg);
      background-size: 20px 20px;
      background-repeat: no-repeat;
      background-position: center center;
      transition: 0.3s ease transform;
    }

    &.active {
      &::after {
        transform: rotate(180deg);
      }
      .collapse__head--overflow {
        & > div {
          text-overflow: unset;
          white-space: unset;
          overflow: unset;
          & > p {
            display: block;
          }
        }
      }
    }
  }

  &__head {
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    transition: 0.3s ease max-height;
    &--content {
      & > * {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    //&--overflow {
    //  & > div {
    //    & > div {
    //      text-overflow: ellipsis;
    //      white-space: nowrap;
    //      overflow: hidden;
    //    }
    //  }
    //}
  }

  &__content {
    cursor: default;
    position: relative;
    transition: 0.3s ease height;
  }

  &__wrapper {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    max-width: 707px;
    font-size: 14px;
    line-height: 20px;
    opacity: 0;
    transition: 0.3s ease opacity;
    padding: 16px 0 8px 0;
    span {
      a {
        color: $green;
        display: block;
      }
    }
    @include for-tablet {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &.active {
    .collapse {
      &__wrapper {
        opacity: 1;
        transform: scaleY(1);
      }
    }
  }

  @include for-tablet {
    line-height: 26px;
    transition: 0.3s ease color;

    &--default {
      padding: 32px 53px;

      &::before,
      &::after {
        top: 36px;
        left: 11px;
      }

      &:hover {
        color: black;

        &::before,
        &::after {
          background-color: black;
        }
      }
    }

    &__head {
      font-size: 21px;
    }
  }
}
