@import '../../../styles/variables';

.loans {
    position: relative;

    &.padding {
        @include for-mobile-only{
            padding-top: 0;
        }
    }

    h2 {
        margin-bottom: 24px;
    }
    h1{
      margin-bottom: 40px;
    }

    &__tabs {
        margin-bottom: 24px;
    }

    &__auto {
        position: absolute;
        right: 0;
    }

    @include for-mobile-only {
        position: relative;

        &__auto {
            top: 52px;
            right: -15px;
        }
    }
    @media screen and (max-width:$mobile) {
        position: relative;
        &__auto {
            top: 35px;
        }
    }

    @include for-tablet {
        &.padding {
            //padding-top: 60px;
        }

        &__tabs {
            position: relative;
        }

        &__auto {
            bottom: 15px;
        }
    }
}
