.modal {

}

.heading {
  font-size: 30px;
  font-weight: bold;
  color: #303133;
  line-height: 1.2;
  margin-bottom: 32px;
}

.subtitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #7D7D7D;
  margin: 20px 0;
}

.text {
  font-size: 14px;
  font-weight: 400;
  // color: #303133;
  margin-bottom: 48px;
}

.image {
  width: 100%;
  margin-bottom: 32px;

  & img {
    width: 100%;
    box-sizing: border-box;
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.back {
  :global .ant-btn {
    color: #909193;
    // border-color: #65B22E;
  }
}

.next {
  :global .ant-btn-primary {
    background-color: #65B22E;
    border-color: #65B22E;
  }
}

.rotate {
  transform: rotate(180deg)
}
.imageWrapper {
  max-height: 326px
}
.iconWrapper {
  background: #FFFFFF;
  border: 0.5px solid #B5B5B5;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  border-radius: 50%;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dots {
  position: absolute;
  display: flex;
  left: 45%;
}

.dot {
  width: 8px;
  height: 8px;
  background: rgba(47, 47, 48, 0.2);
  border-radius: 50%;
  cursor: pointer;
  margin-left: 5px;
}
.active {
  background: #65B22E;
}