@import '../../../styles/variables';

.btn {
    position: relative;
    display: inline-block;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: $borderRadiusMiddle;
    transition-duration: 0.3s;
    transition-property: background-color, border-color, opacity;
    padding: 0 10px;
    width: 100%;
    cursor: pointer;
    border: 2px solid $green;
    user-select: none;

    @include for-tablet-land {
        &:hover {
            opacity: 0.85;
        }
    }

    &:disabled {
        background: rgba(135, 139, 144, 0.3);
        color: white;
        pointer-events: none;
        border-color: transparent;
    }

    &:focus-visible {
        outline: none;
    }

    &:active {
        display: inline-block;
        text-align: center;
        border: 0 none $green;
        border-bottom-width: 2px;
    }

    svg {
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 20px;

        path {
            stroke: $colorInverse;
        }

        &.plusLeft {
            margin-right: 8px;
            width: 14px !important;
            height: 14px !important;
        }

        &.iconLeft {
            margin-right: 8px;
        }

        &.iconRight {
            margin-left: 8px;
        }

        &.chevron {
            margin-left: 16px;
            width: 11px;
            height: 11px;
            vertical-align: text-top;
        }

    }

    span {
        margin: 0 !important;
        display: inline-block !important;
        transition: 0.3s opacity;
        color: $colorInverse !important;
    }

    &--loading {
        position: relative;
        overflow: hidden;

        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 1px;
            right: 0;
            bottom: 0;
            z-index: 1;
        }

        &::before {
            background-color: $bgSecondary;
        }

        &::after {
            transform: translateX(-100%);
            background: linear-gradient(90deg, transparent, rgba($green, 0.2), transparent);
            animation: ssc-loading 1.3s infinite;
        }

        span {
            color: $colorSecondary !important;
            position: relative;
            z-index: 2;
        }
    }

    &--loading-pay {
        position: relative;
        background: rgba(76, 175, 80, 0.5) !important;
        pointer-events: none;

        &:after {
            content: '';
            display: inline-block;
            margin-left: 16px;
            vertical-align: middle;
            border: 2px solid #FFFFFF;
            border-left-color: #a5d7a7;
            border-radius: 100%;
            width: 16px;
            height: 16px;
            animation: spiner 1s linear infinite;
        }
    }

    @media (max-width: 359px) {
        font-size: 14px !important;
    }
}

.green {
    background-color: $green;
}

.green-pay {
    background-color: $green;

    @include for-cursor-device {
        &:hover {
            svg {
                transform: translateX(4px);
                opacity: 0.75;
            }
        }
    }

    &:disabled {
        background-color: rgba(135, 139, 144, 0.3);
    }
}

.sign {
    background-color: $blue;
    border-color: $blue;

    &:after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        margin-left: 8px;
        width: 20px;
        height: 20px;
        background-image: url(./img/sign.svg);
    }
}

.inverted:not(:disabled) {
    color: $green;
    border: 1px solid $green;
    background: transparent;

    span {
        color: $green !important;
    }
}

.slim {
    padding: 0 32px;
    //background: $green;
    //border: none;
    height: 40px;
}

.orange {
    background-color: $orange;
    border-color: $orange;

    &:active {
        border-color: $orange;
    }
}


.bony {
    padding: 0;
    height: auto !important;
    width: auto !important;
    border: none;
    background-color: transparent;

    span {
        color: $green !important;
    }

    svg {
        path {
            stroke: $green !important;
        }

        &.arrowLeft {
            margin-right: 10px;
        }

        &.arrowRight {
            margin-left: 10px;
        }
    }

    &:disabled {
        background: transparent;
        opacity: 0.4;

    }
}

.border {
    background-color: $bgSecondary;
    border-color: $green;

    svg {
        path {
            stroke: $colorPrimary;
        }
    }

    span {
        color: $colorPrimary !important;
    }
}

.shadow {
    font-weight: bold;
    background-color: $bgSecondary;
    border: 1px solid $borderPrimary;
    box-shadow: $shadowSecondary;

    svg {
        path {
            stroke: $colorPrimary;
        }
    }

    span {
        color: $colorPrimary !important;
    }
}

.mini {
    height: 34px;

    span {
        font-size: 14px !important;
        line-height: 30px !important;
    }
}

.default {
    height: 40px;

    span {
        font-size: 16px !important;
        line-height: normal !important;
    }
}

.default-change {
    height: 40px;

    span {
        font-size: 16px !important;
        line-height: 38px !important;
    }
}

.medium {
    height: 50px;

    span {
        font-size: 18px !important;
    }
}

.big {
    height: 56px;

    span {
        font-size: 18px !important;
    }

    &:active {
        display: flex;
    }
}

@include for-tablet {
    .mini {
        height: 40px;

        span {
            font-size: 16px !important;
        }
    }

    .default-change {
        height: 50px;

        span {
            font-size: 16px !important;
            line-height: 48px !important;
        }
    }
}

@include for-mobile-only {
    .default-change {
        padding: 0;

        span {
            font-size: 16px !important;
            line-height: 100% !important;
        }
    }
}

@keyframes ssc-loading {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(100%);
    }
}

@keyframes spiner {
    100% {
        transform: rotate(360deg);
    }
}
