@import '../../../styles/variables';

.articles {
    &--single {
        padding-top: 60px;
        @include for-mobile-only{
            padding-top: 48px;
        }
    }

    &--full {
        padding-top: 0;

        div {
            display: grid;
            grid-gap: 16px;
        }
    }

    h1{
        margin-bottom: 32px;
    }
    h2 {
        margin-bottom: 24px;
    }

    h2 {
        display: flex;

        span {
            margin-right: 12px;
        }
    }

    article {
        position: relative;
        overflow: hidden;
        background-color: $bgSecondary;
        border-radius: 8px;
        box-shadow: $shadowPrimary;

        img {
            height: 185px;
            width: 100%;
            object-fit: fill;
        }

        p {
            padding: 24px 20px;

            time {
                font-size: 14px;
                line-height: 20px;
                color: $colorSecondary;
            }

            span {
                display: block;
                margin: 10px 0;
                font-size: 16px;
                line-height: 24px;
                font-weight: normal;
            }

            a {
                margin-top: 20px;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
            }
        }
    }

   .buttonWrapper{
       margin-top: 24px;
       button{
           grid-column-start: 2;

       }

   }

    @include for-mobile-only {
        &--single {
            div {
                scroll-snap-type: x mandatory;
                white-space: nowrap;
                overflow-x: auto;
                overflow-y: hidden;
                padding: 20px;
                margin: -20px;
                scroll-padding-left: 20px;
            }

            h2 {
                justify-content: space-between;

                span {
                    display: block;
                    width: 240px;
                    white-space: break-spaces;
                    margin-right: 24px;
                }
            }

            article {
                scroll-snap-align: start;
                white-space: normal;
                width: 260px;
                display: inline-block;
                vertical-align: top;

                + article {
                    margin-left: 16px;
                }
            }
        }
        .buttonWrapper{
            margin-top: 12px;
            button{
                grid-column-start: auto;

            }

        }
    }

    @include for-tablet {
        &--single {
            padding-top: 60px;

            h2 {
                span {
                    margin-right: 24px;
                }
            }
        }

        div {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 20px;
        }
    }
}
