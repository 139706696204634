@import "../../../styles/variables";

.container{
    position: relative;
}
.confirmation {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    @include for-tablet-land {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 30px;
    }
}
.title {
    margin-bottom: 24px;
    font-size: 24px;
    line-height: 24px;
    font-weight: bold;
}
.info{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
}
//.idxWrapper{
//    margin-bottom: 24px;
//    position: relative;
//    width: 460px;
//    height: 350px;
//    button{
//        position: absolute;
//        margin-left: auto;
//        margin-right: auto;
//        margin-top: 130px;
//        left: 0;
//        right: 0;
//        text-align: center;
//        width: 200px;
//    }
//}
.iframe{
    max-width: 100%;
    height: 350px;
    border: none;
}

