@import "../../../styles/variables";

.settings {

    &__title {
        font-weight: bold;
        font-size: 24px;
        line-height: 24px;
        margin-bottom: 32px;
    }

    &__wrapper {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        @include for-mobile-only{
            margin: 20px 0 0;
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__slider {
        width: 66%;
        margin: 0 0 16px;
        order: 1;
        @include for-mobile-only{
            width: 100%;
        }
        & div, & input {
            font-size: 18px;
        }
    }

    &__more {
        order: 2;
        color: $green;
        cursor: pointer;
        margin: 0 auto;
        @include for-tablet-land {
            margin-bottom: 35px;
        }
        @include for-mobile-only{
            margin: 24px 0 40px;
            order: 3;
        }
    }
    &__info {
        order: 3;
        margin: 0 0 40px;
        width: 66%;
        @include for-mobile-only{
            margin: 0;
            width: 100%;
            order: 2;
        }
    }
    //&__duration{
    //    p{
    //        margin: 16px 0 24px;
    //    }
    //}
    &__duration {
        max-width: 570px;
        margin-bottom: 40px;
        & > p {
            font-size: 18px;
            line-height: 26px;
            margin: 0 0 24px;
        }
    }
    &__checkboxWrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 24px;
        @include for-mobile-only {
            display: grid;
            grid-template-columns: 1fr;
            gap: 24px;
            grid-gap: 24px;
        }
    }
    &__checkbox {
        display: flex;
        align-items: center;
        label{
            margin-right: 11px;
        }
    }

    &__calc {
        display: grid;
        grid-template-columns: 100px 1fr;
        grid-gap: 8px 80px;
        @include for-mobile-only {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        p {
            font-size: 14px;
            line-height: 20px;
            color: $colorSecondary;
            margin-bottom: 8px;
        }

        h4 {
            font-size: 48px;
            line-height: 62px;
            display: flex;
            align-items: center;
            @include for-mobile-only {
                font-size: 30px;
                line-height: 40px;
            }
        }
        .svgWrapper{
            margin-bottom: -1px;
            @include for-mobile-only {
                margin-bottom: 0;
            }
        }

    }
    &__select{
        width: 32%;
        margin-top: 20px;
        @include for-mobile-only {
            width: 100%;
        }
    }
    &__minor{
        font-size: 13px;
        line-height: 18px;
        margin: 24px 0 40px;
        color: $colorSecondary;
    }
}
