@import "../../../styles/variables";

.personal {
}

.head {
  margin-bottom: 24px;
}

.title {
  margin-bottom: 24px;
  font-size: 24px;
  line-height: 24px;
  font-weight: bold;
}

.inputsGroup {
  display: grid;
  gap: 24px;

  &__main {
    grid-template-columns: 1fr;
    @include for-tablet-land {
      grid-template-columns: repeat(3, 1fr)

    }
  }
  &__common{
    grid-template-areas: "fio"
      "prevFio"
      "birthday"
      "citizenship"
      "phone"
      "additionalPhone"
      "email";
    @include for-tablet-land {
      grid-template-areas:
          "fio prevFio ."
          "birthday citizenship ."
          "phone additionalPhone email"
    }
  }
  &__passportData{
    grid-template-areas: "fio"
      "passSeries"
      "passNumber"
      "passIss"
      "passAuthority"
      "passCodeDepartment"
      "passPlaceRegistration"
      "passPlaceRegistration"
      "passPlaceRegistration";
    @include for-tablet-land {
      grid-template-areas:
          "passSeries passNumber ."
          "passIss passAuthority passCodeDepartment"
          "passPlaceRegistration passPlaceRegistration passPlaceRegistration"
    }
  }
  &__statusData{
    grid-template-areas: "fio"
      "employmentStatus"
      "position"
      "companyName"
      "baseIncome"
      "additionalIncome"
      "additionalIncomeSource"
      "maritalStatus";
    @include for-tablet-land {
      grid-template-areas:
          "employmentStatus . ."
          "position companyName ."
          "baseIncome additionalIncome additionalIncomeSource"
          "maritalStatus . ."
    }
  }
}

.photos {
  display: grid;
  gap: 30px;

  @include for-tablet-land {
    grid-template-columns: repeat(4, 1fr);
  }
}

.cardPhoto {
  display: flex;
  padding: 15px;
  border: 1px solid #4caf50;
  border-radius: 4px;
}

.cardPhoto > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * (1 - var(--tw-space-x-reverse)));
}

.photo {
  flex-shrink: 0;
  margin-right: 16px;
  width: 100px;
  text-align: center;
}
