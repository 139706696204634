@import '../../../styles/variables';

.ap {
    &.padding {
        padding-top: 48px;
    }
    .pageHeader{
        margin-bottom: 32px;
    }
    h2,
    &__tag {
        display: flex;
        align-items: center;
    }

    h1 {
        display: inline-flex;
        align-items: center;
        margin: 0 24px 40px 0;

        span {
            display: inline-block;
            margin-left: 16px;
        }
    }

    &__tooltip {
        display: inline-block;
        vertical-align: top;

        h3 {
            font-size: 21px;
            line-height: 26px;
            margin-bottom: 24px;
        }
        article {
            display: grid;
            grid-gap: 20px;

            div {
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    top: 30px;
                    right: 0;
                    width: 80px;
                    height: 2px;
                    background-color: $green;
                }
            }
        }
    }

    @include for-tablet {
        &.padding {
            padding-top: 60px;
        }

        h2 {
            margin-bottom: 24px;

            span {
                margin-left: 28px;
            }
        }

        &__tooltip {
            article {
                grid-gap: 66px;
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }

    @include for-desktop {
        &__tooltip {
            article {
                grid-template-columns: repeat(4, 1fr);
            }
        }
    }
    @include for-mobile-only{
        h2.pageHeader {
            margin-bottom: 24px;
        }
    }
}
.cardWrapper{
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 20px;
    @include for-mobile-only{
       display: flex;
       flex-wrap: wrap;
        & > * {
            flex-grow: 1;
        }
    }
}

.statusCard__wrapper{
    margin-bottom: 20px;
}
