@import '../../../styles/variables';

.card {
  display: flex;
  flex-wrap: wrap;
  padding: 4px;
  background-color: $bgSecondary;
  box-shadow: $shadowPrimary;
  border-radius: 8px;

  .dashboardInfo {
    width: 100%;
    p {
      margin: 25px 30px 25px;
    }

    hr {
      margin-bottom: 10px;
    }
  }

  .cardInnerWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    & > * {
      flex: 1 1 0;
    }

    @include for-tablet {
      display: grid;
      grid-template-columns: 30% 30% 20% 1fr;
      grid-gap: 5px;
      flex-direction: row;
      padding: 5px;
    }
  }

  .btn {
    margin-top: 24px;
  }

  .label {
    cursor: pointer;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;
    color: #878b90;
  }

  .info {
    margin-bottom: 16px;
    padding-top: 20px;
    padding-left: 16px;
    padding-right: 16px;


    @include for-tablet {
      margin-bottom: 0;
      padding-top: 40px;
      padding-bottom: 30px;
    }

    &__col {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      cursor: pointer;
      @include for-tablet {
        margin-bottom: 4px;
      }
    }

    &__id {
      display: inline-block;
      font-size: 30px;
      line-height: 30px;
      color: #27273f;
      font-weight: bold;
      word-break: break-word;
    }

    &__tag {
      cursor: pointer;
      display: inline-block;
      margin-left: 10px;
      padding: 4px 6px 2px;
      font-size: 12px;
      line-height: 12px;
      color: $green;
      background: rgba(76, 175, 80, 0.1);
      border-radius: 3px;
    }

    &__online {
      display: flex;
      margin-top: 10px;
      font-size: 16px;
      line-height: 24px;
      color: #27273f;
    }
  }

  .conditions {
    margin-bottom: 24px;
    padding-left: 16px;
    padding-right: 16px;
    @include for-tablet {
      margin-bottom: 0;
      padding-top: 30px;
      padding-bottom: 30px;
    }

    &__items {
      display: flex;
      flex-direction: column;
      font-size: 16px;
      line-height: 24px;
      color: #27273f;
      cursor: pointer;
    }

    &__btn {
      display: none;
      @include for-tablet {
        display: block;
      }
    }
  }

  .subject {
    margin-bottom: 24px;
    padding-left: 16px;
    padding-right: 16px;

    @include for-tablet {
      margin-bottom: 0;
      padding-top: 30px;
      padding-bottom: 30px;
    }

    &__value {
      cursor: pointer;
      font-size: 16px;
      line-height: 24px;
      color: #27273f;
    }
  }

  .status {
    padding: 20px 16px 24px;
    border-radius: 0px 0px 8px 8px;
    @include for-mobile-only{
      .label {
        margin-top: 10px;
      }
    }

    b {
      cursor: pointer;
    }

    @include for-tablet {
      padding-top: 30px;
      padding-bottom: 30px;
      border-radius: 0px 8px 8px 0px;
    }
    @include for-mobile-only {
      padding: 0 16px 24px;
    }

    &--green {
      background: rgba(76, 175, 80, 0.1);

      .status__text {
        color: $green;
      }
    }

    &--red {
      background: rgba(255, 63, 66, 0.1);

      .status__text {
        color: $red;
      }
    }

    &--orange {
      background: rgba(255, 184, 0, 0.15);

      .status__text {
        color: $orange;
      }
    }

    &__text {
      font-size: 16px;
      line-height: 24px;
      color: #27273f;
      word-break: break-word;
      @include for-mobile-only{
        word-break: break-word;
      }
    }

    &__btn {
      @include for-tablet {
        display: none;
      }
    }
  }
}

.loanModal {

  a {
    color: $green;
  }

  p {
    font-size: 18px;
    color: $colorPrimary;
  }

  h4 {
    margin: 24px 0 ;
  }

  hr {
    position: relative;
    width: 150%;
    left: -50px;
    margin-top: 20px;
  }

  ul {
    margin-bottom: 32px;

    li {
      list-style: none;
      margin-left: 20px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        left: -17px;
        top: 8px;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        background: $green;
      }
    }
  }

  label {
    margin-bottom: 24px;
  }

  input[type=checkbox] {
    margin-bottom: 32px;
  }

  .modalSpacer {
    margin: 24px 0;
  }

  .modalHalfSpacer {
    margin-top: 24px;
  }

  .modalText {
    font-size: 16px;
    white-space: pre-wrap;
  }

  .modalTextWrapper {
    .modalText p {
      font-size: 16px;
      white-space: pre-wrap;
      margin-bottom: 10px;
    }
  }

  .modalNote {
    padding: 32px;
    background: $borderSecondary;
    border-radius: 8px;
    margin-top: 24px;
  }

  .modalInfoTextWrapper {
    margin: 24px 0;
    display: flex;
    flex-direction: column;
    p:not(:last-child) {
      margin-bottom: 2px;
    }
  }

  .modalTitle {
    font-size: 14px;
    color: $colorSecondary;
    margin-bottom: 5px;
  }

  .modalPhone {
    margin: 32px 0;
  }

  .modalInput {
    margin: 24px 0;

    p {
      margin-top: 12px;
    }
  }

  .buttonWrapper {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr 1fr;
  }



  .photosWrapper {
    & > div {
      margin-bottom: 24px;
    }
    label {
      margin-bottom: 0;
    }
  }

  .title {
    margin-bottom: 24px;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
  }

  .errorText {
    font-size: 16px;
    color: $red;
  }
}
