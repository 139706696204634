.card__header{
    display: flex;
    gap: 10px;
}
.card__subtitle{
    font-size: 12px;
    line-height: 100%;
    font-style: normal;
    font-weight: 500;
    padding: 4px 6px 4px;
    width: fit-content;
    display: inline-flex;
    border-radius: 3px;
}
.card__questionIcon{
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    border-radius: 15px;
    width: 25px;
    height: 24px;
    color: gray;
    text-align: center;
    cursor: pointer;
}

.bronze {
    border-radius: 3px;
    background: #CD7F32;
    color: white;
}

.silver {
    border-radius: 3px;
    background: #c0c0c0;
}

.gold {
    border-radius: 3px;
    background: #FFD700;
}
