@import '../../../styles/variables';

.condensed {
  padding: 20px;
  border-radius: 8px;

  span {
    display: block;
    margin-bottom: 6px;
    font-size: 14px;
    line-height: 20px;
    color: $colorSecondary;
  }

  &.info {
    background-color: rgba($orange, 0.1);
    color: $orange;
  }
  &.success {
    background-color: rgba($green, 0.1);
    color: $green;
  }
  &.fail {
    background-color: rgba($red, 0.1);
    color: $red;
  }
}
