@import '../../../styles/variables';

.inputWrapper {
  position: relative;
  width: 100%;

  &--auto {
    input {
      cursor: pointer;
    }
  }

  &--top {
    .selectWrapper {
      top: auto;
      bottom: 120%;
    }
    .select {
      max-height: 240px;
    }

    .icon {
      transform: translateY(-50%) rotate(-180deg);
    }

    &.active {
      &Icon {
        .icon {
          transform: translateY(-50%) rotate(0deg) !important;
        }
      }
    }

  }

  .icon--chevron {
    pointer-events: none;
  }

  &.active {
    &Placeholder {
      .placeholder {
        width: auto;
        transform: scale(0.8) translateY(calc(-50% - 36px));

        &::before {
          opacity: 1;
        }
      }
    }
    &Icon {
      .icon {
        transform: translateY(-50%) rotate(-180deg);
      }
      .input {
        border-color: $green;
      }
    }
  }

  &Confirm {
    width: 50%;
  }
}

input::-webkit-date-and-time-value {
  text-align: left;
}

input[type=number] {
  -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.input {
  background-color: $bgSecondary;
  border: 1px solid $borderPrimary;
  border-radius: $borderRadiusMiddle;
  height: 56px;
  width: 100%;
  padding: 0 28px 0 18px;
  text-overflow: ellipsis;
  color: $colorPrimary;

  &Confirm {
    letter-spacing: 7px;
  }

  &:disabled {
    opacity: 0.85;
    pointer-events: none;
    background-color: rgba(242, 242, 242, 1);

    + img {
      opacity: 0.25;
    }
  }
}

.placeholder {
  padding: 0 4px;
  font-size: 16px;
  color: $colorSecondary;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 15px;
  pointer-events: none;
  transform: translateY(-50%);
  transform-origin: left;
  transition: transform 0.2s, width 0.2s;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 85%;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 10px;
    left: -4px;
    right: -4px;
    bottom: 0;
    background-color: #fff;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.2s;
  }

  &--disabled {
    &::before {
      background-color: #f4f4f4 !important;
    }
  }
}

.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  cursor: pointer;
  transition: transform 0.3s;
}

.selectWrapper {
  display: table;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 2;
  background-color: $bgSecondary;
  -webkit-box-shadow: $shadowRepeater;
  -moz-box-shadow: $shadowRepeater;
  box-shadow: $shadowSecondary;
  cursor: pointer;
  border-radius: $borderRadiusMiddle;
}

.select {
  height: fit-content;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 160px;
  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
  }
}

.multipleValue {
  position: absolute;
  left: 18px;
  top: 2px;
  bottom: 2px;
  display: flex;
  align-items: center;
  background: $bgSecondary;
  padding-right: 3px;
}

.multipleValueNative {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: $bgSecondary;
  width: 100%;
  border: 1px solid #DDE0E4;
  border-radius: 8px;
  height: 56px;
  padding-left: 18px;
  padding-right: 30px;
  white-space: nowrap;
  overflow: hidden;
  line-height: 3.5;
  text-overflow: ellipsis;
}

.selectInputNative {
  opacity: 0;
}

.option {
  position: relative;
  z-index: 1;
  padding: 8px;

  &:hover {
    background-color: $bgSecondary;
  }
}

.dateValue {
  position: absolute;
  left: 18px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  width: 50%;
  cursor: pointer;
}

.gray {
  .input,
  .placeholder,
  .placeholder::before,
  .select {
    background-color: $bgPrimary;
  }
}



.large {
  .input {
    height: 80px;
    font-size: 36px;
    line-height: 48px;
  }
  &.active {
    &Placeholder {
      .placeholder {
        transform: scale(0.8) translateY(calc(-50% - 51px));
      }
    }
  }
}

.loader {
  display: none;
  position: absolute;
  top: calc(50% - 16px);
  right: -56px;
  &Active {
    display: inline-block;
    animation: loader 1.3s linear infinite;
  }
}

.successIcon,
.errorIcon {
  display: none;
  position: absolute;
  top: calc(50% - 16px);
  right: -56px;
  &Active {
    display: inline-block;
  }
}

.red {
  color: $red;
}

.green {
  color: $green;
}

@keyframes loader {
  100% {
    transform: rotate(360deg);
  }
}
