@import '../../../styles/variables';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 16;
  opacity: 0;
  transition: opacity 0.3s ease;

  &--show {
    opacity: 1;
  }

  &__wrapper {
    position: fixed;
    background: $bgSecondary;
    padding: 0 5.56% 40px ;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 16;
    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      border: 6px solid $green;
    }
  }

  &__close {
    position: sticky;
    top: 0;
    z-index: 10;
    div{
      position: absolute;
      right: -28px;
      top: 12px;
      @include for-mobile-only{
        right: -14px;
      }
    }
  }

  &__top {
    h2{
        font-weight: 600;
    }
    &:first-child {
      margin-top: 40px;
      margin-bottom: 26px;
    }
    &:last-child {
      margin-bottom: 32px;
    }
    &:only-of-type {
      margin-top: 40px;
      margin-bottom: 32px;
    }
  }

  ::-webkit-scrollbar {
    width: 4px;

    &-track {
      opacity: 0;
    }

    &-thumb {
      background: $colorSecondary;
      border-radius: 4px;

      &:hover {
        background: $iconPrimary;
      }
    }
  }

  @include for-tablet {
    &__bg {
      position: fixed;
      background: rgba(3, 3, 3, 0.4);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 16;
    }

    &__wrapper {
      padding: 0 40px 40px;
      border-radius: $borderRadiusLarge;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 94%;
      max-width: 520px;
      height: auto;
      max-height: 96vh;

      &--big {
        max-width: 800px;
      }

      &--large {
        max-width: 1100px;
      }
      &--small{
        max-width: 440px;
      }
    }
  }
}
