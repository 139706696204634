@import '../../styles/variables';

.header {
  position: fixed;
  z-index: 15;
  background: $bgSecondary;
  height: 58px;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 20px;

  &,
  &__wide,
  &__right {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__wide {
    width: 100%;
    margin-left: 24px;
  }

  &__loan {
    display: flex;
    justify-content: center;
    align-items: center;
    button{
      display: flex;
      @include for-mobile-only {
        padding: 0 10px;
      }
    }
    span {
      display: none;
    }
  }

  &__last {
    margin-left: 24px;

    &--tablet {
      display: none;
    }
  }

  @include for-mobile-only {
    &__loan {
      //width: 131px;
    }
  }

  @include for-tablet-land {
    position: absolute;
    padding: 0 40px 0 20px;
    max-width: $maxContainer;

    background-color: transparent;
    height: 80px;

    &__wide {
      margin-left: 0;
    }

    &__logo {
      position: fixed;
      top: 11px;
      left: 20px;
    }

    &__loan {
      margin-left: 320px;
    }

    &__last {
      &--mobile {
        display: none;
      }
      &--tablet {
        display: block;
      }
    }
  }

  @include for-notebook {
    &__loan {

      span {
        display: inline-block;
        vertical-align: middle;
      }

      button {
        margin-right: 24px;
      }

      span {
        font-size: 14px;
        line-height: 20px;
        color: $colorSecondary;
      }
    }
  }

  @include for-wide {
    max-width: 100%;

    &__wide {
      padding: 0;
      margin: 0 auto;
      width: 1060px;
    }

    &__loan {
      margin-left: 0;
    }
  }
}
