@import '../../../../styles/variables';

.card {
  background-color: $bgPrimary;
  border-radius: $borderRadiusLarge;
  padding: 24px 24px 24px 20px;
  cursor: pointer;
  display: flex;

  @include for-tablet {
    padding: 24px 30px;
  }

  &__wrapper {
    display: flex;
  }

  &__icon {
    width: 48px;
    height: 48px;
    flex-shrink: 0;
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
    color: $green;
    margin-left: 16px;

    @include for-tablet {
      margin-left: 32px;
    }
  }


}
