@import '../../../styles/variables';

.share {
  p {
    font-size: 14px;
    line-height: 20px;
    color: $colorSecondary;
    margin-bottom: 16px;
  }

  a + a {
    margin-left: 12px;
  }
}
