@import './variables';
.title {
    font-weight: bold;
}

h1.title {
    font-size: 28px;
    line-height: 38px;
}
h2.title {
    font-size: 20px;
    line-height: 24px;
}
h3.title {
    font-size: 18px;
    line-height: 24px;
}
h4.title {
    font-size: 16px;
    line-height: 20px;
}

@include for-mobile-new {
    h2.title {
        font-size: 24px;
        line-height: 28px;
    }
    h3.title {
        font-size: 21px;
        line-height: 26px;
    }
    h4.title {
        font-size: 18px;
        line-height: 26px;
    }
}

@include for-tablet {
    h1.title {
      font-size: 36px;
      line-height: 48px;
    }
}
