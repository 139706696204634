@import '../../../styles/variables';

.profilePhoto {
  display: flex;
  flex-direction: column;
  align-items: center;

  picture, img {
    max-width: 200px;
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: flex;
    margin-bottom: 15px;
    border-radius: 100%;
  }

  button {
    width: fit-content;
  }
  .inputFile{
    display: none;
  }

  .imageWrapper {
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    @include for-mobile-only{
        align-items: flex-start;
    }

    svg{
      width: 100%;
      height: 100%;
    }

    button{
      span{
          font-weight: 500;
      }
      @include for-mobile-only{
        margin-top: 10px;
      }
    }
  }
}
