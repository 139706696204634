@import '../../../styles/variables';

.wrapper {
  padding: 24px 20px;
  background-color: $bgSecondary;
  box-shadow: $shadowPrimary;
  border-radius: $borderRadiusLarge;

  @include for-tablet {
    &--default {
      padding: 40px 30px;
    }

    &--small {
      padding: 30px;
    }
  }
}
